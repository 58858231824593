import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { CertificatesService } from '../../services/certificates.service';
import { TenantId } from '../../Models/url';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-create-certificates',
  templateUrl: './create-certificates.component.html',
  styleUrls: ['./create-certificates.component.css'],
})
export class CreateCertificatesComponent implements OnInit {
  studentId: any;
  AllCertificatesList = [];
  StudentDetails = {};
  studentsApplied: any;
  showButton = '';
  assigned_tests = [];
  showAll: boolean = false;
  practice: boolean = false;
  test: boolean = false;
  qaTest: boolean = false;
  getAllcertificate: any;
  flag: string;
  isNoCertificate: boolean = false;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  constructor(
    public service: CertificatesService,
    private router: Router,
    private userSvc: UserService,
    private cms: CommonService
  ) {}

  ngOnInit() {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.ShowAll();
  }
  Test() {
    this.showAll = false;
    this.practice = false;
    this.test = true;
    this.qaTest = false;
    this.flag = 'test';
    this.AllCertificatesList = [];
    this.getAllCertificatesList();
  }
  ShowAll() {
    this.showAll = true;
    this.practice = false;
    this.test = false;
    this.qaTest = false;
    this.flag = '';
    this.AllCertificatesList = [];
    this.getAllCertificatesList();
  }
  Practice() {
    this.showAll = false;
    this.practice = true;
    this.test = false;
    this.qaTest = false;
    this.flag = 'quiz';
    this.AllCertificatesList = [];
    this.getAllCertificatesList();
  }
  QATest() {
    this.showAll = false;
    this.practice = false;
    this.test = false;
    this.qaTest = true;
    this.flag = 'qatest';
    this.AllCertificatesList = [];
    this.getAllCertificatesList();
  }

  async getAllCertificatesList() {
    let url =
      APP_URLS.AllCertificates +
      '?filter={"criteria":[{"key":"type","value":"' +
      this.flag +
      '","type":"eq"}]}';
    this.AllCertificatesList = [];
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      this.isNoCertificate = res.all.length ? false : true;
      if (res.all.length > 0) {
        // res.all.forEach((x: any) => {
        //   if (x.type == this.flag) {
        //     return this.AllCertificatesList.push(x);
        //   } else if (this.flag != 'test' && this.flag != 'qatest' && this.flag != 'quiz') {
        return (this.AllCertificatesList = res.all);
        //   }
        // })
      }
    });
  }
  // async getStudentDetails() {
  //   let url = APP_URLS.profileDetails + this.studentId + '?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}'

  //   await this.service.getCall(url).subscribe((res: any) => {
  //     this.StudentDetails = res.details
  //     this.assigned_tests = res.details.assigned_tests

  //     this.buttonText();
  //   })
  // }

  // async getstudentAppliedTest() {
  //   let url = APP_URLS.studentAppliedTest

  //   await this.service.getCall(url).subscribe((res: any) => {
  //     this.studentsApplied = res
  //   })
  // }
  // buttonText() {
  //   if (this.AllCertificatesList.length > 0) {
  //     this.AllCertificatesList.forEach((x: any) => {
  //       if (x.testprice != 0) {
  //         x.showButton = "Add Test"
  //       }
  //       else if (x.assigned_students.length > 0) {
  //         var filterData = x.assigned_students.filter(y => y == this.studentId)

  //         if (filterData.length>0) {
  //           x.showButton = "Take Test"
  //         }
  //         if(filterData==0){
  //           x.showButton="waiting for Aprovel"
  //         }
  //         else {
  //           x.assigned_students.forEach(y => {
  //             if (this.assigned_tests.length > 0) {
  //               this.assigned_tests.forEach((z: any) => {
  //                 if (y == z._id) {
  //                   x.showButton = "Test Assigned"
  //                 }
  //                 else {
  //                   x.showButton = "Take Test"
  //                 }
  //               })
  //             }
  //             else {
  //               x.showButton = "Take Test"
  //             }
  //           });

  //         }

  //       }
  //       else {
  //         x.showButton = "Take Test"
  //       }
  //     })
  //   }
  // }

  waitingForApproval() {
    this.service.showWarning(
      'Your request for taking test already sent. Admin will review your request and update you through email and notification. Once accepted, you can start taking your test.'
    );
  }

  selectCretificate(data) {
    if (data.apply_status == 'assigned') {
      localStorage.setItem('certificateForCourseId', data._id);
      this.router.navigate(['app/selectCertificateDetails/' + data._id]);
    } else {
      this.service.showError('Test is not assigned');
    }
  }

  applyTest(data) {
    let url = APP_URLS.applyTest + data._id;
    var payload = {
      student: this.studentId,
      option: 'add',
      tenantId: TenantId,
    };
    this.service.putCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode == 200 || res.respCode == 201 || res.respCode == 204) {
          this.userSvc.showSuccess(res.respMessage);

          this.getAllCertificatesList();
        } else {
          this.userSvc.showError(res.respMessage);
        }
      },
      (err) => {}
    );
  }
}
