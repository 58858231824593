import { Component, OnInit } from '@angular/core';
import { APP_URLS, TenantId } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  isLoading = false;
  studentId: any;
  profilePermissions: any;
  notificationCount = 0;
  Opentickets = 0;

  isActive = 1;
  routesForMyWork = [
    {
      id: 1,
      title: 'Profile Summary',
      value: 'profile-summary',
      perKey: 'Profile summary',
    },
    {
      id: 2,
      title: 'Admission Application',
      value: 'admission',
      perKey: 'Admission Application',
    },
    {
      id: 3,
      title: 'Book For Interview',
      value: 'bookinterview',
      perKey: 'Book For Interview',
    },
    {
      id: 4,
      title: 'Action Center',
      value: 'action-center',
      perKey: 'Action Center',
    },
    {
      id: 5,
      title: 'Attendance',
      value: 'attendance',
      perKey: 'Attendance Records',
    },
    {
      id: 6,
      title: 'Progression',
      value: 'progression',
      perKey: 'Progression',
    },
    {
      id: 7,
      title: 'Certificates',
      value: 'certificate',
      perKey: 'Certificates',
    },
    {
      id: 8,
      title: 'Support Tikcet',
      value: 'support-ticket',
      perKey: 'Support tickets',
    },
    {
      id: 9,
      title: 'Complaint',
      value: 'complaint',
      perKey: 'Complaints',
    },
    {
      id: 10,
      title: 'Notifications',
      value: 'notification',
      perKey: 'Notifications',
    },
    {
      id: 11,
      title: 'Feedbacks',
      value: 'feedback',
      perKey: 'Feedbacks',
    },
  ];

  constructor(
    public service: MyProfileService,
    private router: Router,
    public userSvc: UserService
  ) {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (event.url == '/app/profile/admission/personalDetails') {
          this.isActive = 2;
        }
      }
    });
    var profileData = service.getPermissionData();
    this.profilePermissions = profileData?.profile_permissions;

    service.getPermissionObsData().subscribe((res) => {
      this.profilePermissions = res?.profile_permissions;
    });
  }

  ngOnInit() {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.userSvc.getTicketCount().subscribe((data: any) => {
      this.Opentickets = data;
    });
    this.userSvc.getNotifyCount().subscribe((data: any) => {
      this.notificationCount = data;
    });

    this.getSupportTicket();
    this.getNotificationList();
    this.router.navigate(['app/profile/profile-summary']);
  }

  myWorkRoute(link) {
    if (this.isActive === link.id) {
      return;
    }
    this.isActive = link.id;
    this.router.navigate(['app/profile', link.value]);
  }

  getSupportTicket() {
    let url = APP_URLS.v1.supportTicket;
    this.service.getCall(url).subscribe((data: any) => {
      var count = 0;
      if( data?.list?.length ){
        data.list.forEach((x) => {
          if( x.open_closed_thread == 'open' ){
            count = count + 1;
          }
        });
      }
      this.Opentickets = count;
    });
  }

  getNotificationList() {
    let url =
      APP_URLS.notification +
      '[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"},{"key":"student","value":"' +
      this.studentId +
      '","type":"eq"},{"key":"active","value":"active","type":"eq"},{"key":"frontorback","value":"front","type":"or"},{"key":"frontorback","value":"frontandback","type":"or"}]}';
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.notificationCount = res?.noti_new;
    });
  }
}
