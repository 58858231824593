import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css'],
})
export class AboutusComponent implements OnInit {
  tenantInfo: any = tenantInfo;

  constructor() {}

  userRole: any;
  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
  }
}
