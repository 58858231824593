import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAlphabetOnly]'
})

export class AlphabetOnlyDirective {
  key;
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    this.key = event.keyCode;
    // if ((this.key >= 15 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 105) || ( this.key == 32 )) {
    //   event.preventDefault();
    // }

    if ((this.key >= 15 && this.key <= 36) || (this.key > 40 && this.key < 45) || (this.key > 46 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 222)) {
      event.preventDefault();
    }
  }
}