import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { filter } from 'rxjs/operators';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vle-records',
  templateUrl: './vle-records.component.html',
  styleUrls: ['./vle-records.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VleRecordsComponent implements OnInit {
  p: any = 1;
  countPagination = 50;
  isLoading = false;
  isCourseSelect = false;
  vleRecords: any[] = [];
  progressDetails: any[] = [];
  overAllProgress: any = null;
  generalPortfolioProgress: any = null;
  filteredVleRecord: any[] = [];
  displayName = '';
  courseName: string = '';
  courseList: any[] = [];
  courseType: string = '';
  taskType: any[] = [];
  studentDetail: any = {};
  courseTypeList = [
    {
      value: '',
      name: 'All',
    },
    {
      value: 'lessoncomplete',
      name: 'Lesson Complete',
    },
    {
      value: 'assignment',
      name: 'Assignment',
    },
    {
      value: 'downloadcomplete',
      name: 'Downloads',
    },
    {
      value: 'test',
      name: 'Test',
    },
    {
      value: 'qatest',
      name: 'QA Test',
    },
    {
      value: 'quiz',
      name: 'Quiz',
    },
    {
      value: 'general_portfolio',
      name: 'General Portfolio',
    },
    {
      value: 'certificate',
      name: 'Certificate',
    },
  ];
  studentId: any;
  role = '';
  routeRecords: Subscription;

  constructor(public service: MyProfileService, private route: ActivatedRoute) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getApprenticeDetails();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getApprenticeDetails();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {}

  onChangePage(pageOfItems: Array<any>) {
    this.p = pageOfItems;
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  getProgession(id) {
    this.isLoading = true;
    // let url =
    //   APP_URLS.v1.getMyApprentice +
    //   '?target=progression&courseApplicationId=' +
    //   data._id;

    this.vleRecords = [];
    this.filteredVleRecord = [];

    let url =
      APP_URLS.v1.getMyApprentice +
      '?target=progression&courseApplicationId=' +
      id +
      '&student_id=' +
      this.studentId;
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.isLoading = false;
      this.taskType = [];
      if (res.overAllProgress) {
        this.overAllProgress =
          res.overAllProgress !== undefined ? res.overAllProgress : {};
        this.progressDetails =
          res.progressRes?.length > 0 ? res.progressRes : [];
        if (this.progressDetails?.length > 0) {
          this.taskType.push(this.getTaskName(''));
          this.progressDetails.forEach((p) => {
            this.taskType.push(this.getTaskName(p?._id?.chapter_type));
          });
        }
      }

      if (res?.portfolioEvidance?.length > 0) {
        this.vleRecords = res?.portfolioEvidance;
        this.filteredVleRecord = this.vleRecords;
      } else {
        this.vleRecords = [];
      }

      if (res?.generalPortfolioProgress) {
        this.taskType.push(this.getTaskName('general_portfolio'));
        this.generalPortfolioProgress =
          res?.generalPortfolioProgress !== undefined
            ? res?.generalPortfolioProgress
            : null;
      } else {
        this.generalPortfolioProgress = null;
      }
    });
  }

  getApprenticeDetails() {
    this.isLoading = true;
    let url =
      APP_URLS.v1.getMyApprentice +
      '?target=courseApplicationList&student_id=' +
      this.studentId;
    this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.isLoading = false;
        if (res?.courseApplicationList?.length > 0) {
          const courseDetils = res?.courseApplicationList;
          let courseArray = [];
          courseDetils.forEach((course) => {
            course?.applications?.forEach((app) => {
              let obj = {};
              obj['_id'] = app?._id;
              app?.courseAs.forEach((data) => {
                obj['name'] = data?.name;
              });
              courseArray.push(obj);
            });
          });
          this.courseList = courseArray;
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  changeCourse(data): void {
    this.isCourseSelect = true;
    this.courseName = data;
    this.courseType = '';
    this.p = 1;
    this.getProgession(data);
  }

  typeChange(data): void {
    this.isLoading = true;
    if (data == '') {
      this.filteredVleRecord = this.vleRecords;
    } else {
      this.filteredVleRecord = this.vleRecords.filter(
        (r) => r?.exam_type == data
      );
    }
    this.p = 1;
    this.isLoading = false;
  }

  getTaskName(key: any) {
    let obj = {};
    switch (key) {
      case '':
        obj['label'] = 'All';
        obj['value'] = key;
        break;

      case 'assignment':
        obj['label'] = 'Assignment';
        obj['value'] = key;
        break;

      case 'test':
        obj['label'] = 'Test';
        obj['value'] = key;
        break;

      case 'qatest':
        obj['label'] = 'Qa test';
        obj['value'] = key;
        break;

      case 'quiz':
        obj['label'] = 'Quiz';
        obj['value'] = key;
        break;

      case 'lessoncomplete':
        obj['label'] = 'Lesson';
        obj['value'] = key;
        break;

      case 'lesson':
        obj['label'] = 'Lesson';
        obj['value'] = key + 'complete';
        break;

      case 'certificate':
        obj['label'] = 'Certificate';
        obj['value'] = key;
        break;

      case 'downloadcomplete':
        obj['label'] = 'Download';
        obj['value'] = key;
        break;

      case 'downloads':
        obj['label'] = 'Downloads';
        obj['value'] = 'downloadcomplete';
        break;

      case 'general_portfolio':
        obj['label'] = 'General Portfolio';
        obj['value'] = key;
        break;

      default:
        obj['label'] = key;
        obj['value'] = key;
        break;
    }

    return obj;
  }
}
