import { AfterContentInit, Component, OnInit } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { NavigationEnd, Router } from '@angular/router';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-student-my-work',
  templateUrl: './student-my-work.component.html',
  styleUrls: ['./student-my-work.component.css'],
})
export class StudentMyWorkComponent implements OnInit, AfterContentInit {
  isWallet = true;
  routesForMyWork = [];

  profilePermissions: any;
  isActive = 1;
  isKYCSubmit = false;
  settingsDetails = {
    myWorkCurrency: '',
  };

  constructor(
    private router: Router,
    public service: MyProfileService,
    private toast: UserService
  ) {}

  ngOnInit(): void {
    this.generateLeftSideMenu();
    var settingsData = localStorage.getItem('settingsData');
    if (settingsData != undefined && typeof settingsData == 'string') {
      this.settingsDetails = JSON.parse(settingsData);
      if (this.settingsDetails != null) {
        this.isWallet =
          this.settingsDetails?.myWorkCurrency == 'BP' ? false : true;
        this.generateLeftSideMenu();
      }
    } else {
      this.getDropdowns();
    }

    this.getKYCDetails();
    this.router.navigate(['app/student-my-work', 'student-work-summary']);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const thePath = val.url;
        const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1);
        this.routesForMyWork.forEach((r) => {
          if (r.value == lastItem) {
            this.isActive = r.id;
            this.router.navigate(['app/student-my-work', lastItem]);
          }
        });
      }
    });
  }

  generateLeftSideMenu() {
    this.routesForMyWork = [
      {
        id: 1,
        title: 'My Tasks',
        value: 'student-work-summary',
      },
      {
        id: 2,
        title: this.isWallet ? 'Wallet' : 'Bonus Point (BP)',
        value: 'student-wallet',
      },
      {
        id: 3,
        title: 'KYC',
        value: 'student-kyc',
      },
      {
        id: 4,
        title: 'Withdraw',
        value: 'student-withdraw',
      },
      {
        id: 5,
        title: 'Channel Account',
        value: 'student-channel-account',
      },
    ];
  }

  getDropdowns() {
    let url = APP_URLS.v1.settingsData;
    this.service.getCall(url).subscribe((res: any) => {
      this.isWallet = res?.myWorkCurrency == 'BP' ? false : true;
      this.generateLeftSideMenu();
    });
  }

  ngAfterContentInit(): void {}
  myWorkRoute(e, link) {
    if (!this.isKYCSubmit && link.value == 'student-withdraw') {
      e.stopImmediatePropagation();
      this.toast.showError('Need to Update KYC');
      return;
    }

    if (this.isActive === link.id) {
      return;
    }
    this.isActive = link.id;
    this.router.navigate(['app/student-my-work', link.value]);
  }

  getKYCDetails() {
    let url = APP_URLS.v1.kyc;
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.respCode === 202) {
        if (res.findRes === null) {
          this.isKYCSubmit = false;
          localStorage.setItem('KYC_COMPLETE', 'false');
          return;
        }
        this.isKYCSubmit = true;
        localStorage.setItem('KYC_COMPLETE', 'true');
      }
    });
  }
}
