import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-spr-main',
  templateUrl: './spr-main.component.html',
  styleUrls: ['./spr-main.component.css'],
})
export class SprMainComponent implements OnInit {
  tabPanel = 'spr';
  studentId = '';
  selectStudentName = '';
  studentList: any[] = [];
  role = '';
  routeRecords: Subscription;
  isEnableTab = false;

  constructor(
    private router: Router,
    public service: MyProfileService,
    private route: ActivatedRoute
  ) {
    this.role = localStorage.getItem('role');
    this.routeRecords = this.route.paramMap.subscribe((params) => {
      this.studentId = params.get('id');
    });
  }

  ngOnInit(): void {
    if (this.role == 'employer') {
      if (this.studentId != null) {
        this.isEnableTab = true;
      }
      this.getApprenticeDetails();
    }

    if (this.role == 'student') {
      this.isEnableTab = true;
    }
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  toggleTab(val: string): void {
    this.tabPanel = val;
  }

  onChangeFun(e): void {
    this.isEnableTab = true;
    this.studentId = e.target.value;
    this.studentList.forEach((data) => {
      if (data?.students?._id == this.studentId) {
        this.selectStudentName = data?.students?.displayName;
      }
    });
    this.router.navigate([`app/employer-myspr/` + this.studentId]);
  }

  getApprenticeDetails() {
    let url = APP_URLS.v1.getEmployeeUserList + '?target=otj';
    this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        if (res?.myUserList) {
          this.studentList = res.myUserList ? res.myUserList : [];
          this.studentList.forEach((data) => {
            if (data?.students?._id == this.studentId) {
              this.selectStudentName = data?.students?.displayName;
            }
          });
        }
      },
      (err) => {
        this.studentList = [];
      }
    );
  }
}
