import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.css'],
})
export class AppEmployerProfileComponent implements OnInit {
  studentId: any;
  objLength: number;
  Opentickets = 0;
  profilePermissions: any;
  isActive = 1;
  supportTicketCount = 0;

  routesForMyWork = [
    {
      id: 1,
      title: 'Profile Summary',
      value: 'emp-profile-summary',
    },
    {
      id: 2,
      title: 'Action Center',
      value: 'emp-action-center',
    },
    {
      id: 3,
      title: 'My Apprentice',
      value: 'my-apprentice',
    },
    {
      id: 4,
      title: 'Download Center',
      value: 'download-center',
    },
    {
      id: 5,
      title: 'Support Tickets',
      value: 'emp-support-ticket',
    },
  ];

  constructor(
    public service: MyProfileService,
    public userSvc: UserService,
    private router: Router
  ) {
    var profileData = service.getPermissionData();
    this.profilePermissions = profileData?.profile_permissions;
    service.getPermissionObsData().subscribe((res) => {
      this.profilePermissions = res?.profile_permissions;
    });
  }

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.userSvc.getTicketCount().subscribe((data: any) => {
      this.supportTicketCount = data;
    });
    this.router.navigate(['app/employer-profile', 'emp-profile-summary']);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const thePath = val.url;
        const lastItem = thePath.substring(thePath.lastIndexOf('/') + 1);
        this.routesForMyWork.forEach((r) => {
          if (r.value == lastItem) {
            this.isActive = r.id;
            this.router.navigate(['app/employer-profile', lastItem]);
          }
        });
      }
    });
    this.getSupportTicketCount();
  }

  getSupportTicketCount(): void {
    let url = APP_URLS.v1.supportTicket ;
    this.service.getCall(url).subscribe((res: any) => {
      var count = 0;
      if( res?.list?.length ){
        res.list.forEach((x) => {
          if( x.open_closed_thread == 'open' ){
            count = count + 1;
          }
        });
      }
      this.supportTicketCount = count;
    });
  }

  myWorkRoute(link) {
    if (this.isActive === link.id) {
      return;
    }
    this.isActive = link.id;
    this.router.navigate(['app/employer-profile', link.value]);
  }
}
