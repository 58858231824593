import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloadprospectus',
  templateUrl: './downloadprospectus.component.html',
  styleUrls: ['./downloadprospectus.component.css']
})
export class DownloadprospectusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
