import { Component, OnInit } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';

@Component({
  selector: 'app-emp-otj',
  templateUrl: './emp-otj.component.html',
  styleUrls: ['./emp-otj.component.css'],
})
export class EmpOtjComponent implements OnInit {
  p: any = 1;
  countPagination = 10;
  isLoading = false;
  statusList: any[] = [];
  atLabActivityDetails = [];

  constructor(public service: CourseService) {}

  ngOnInit(): void {
    this.getAtLabRecords();
  }

  onChangePage(pageOfItems: Array<any>) {
    this.p = pageOfItems;
  }

  getAtLabRecords(): void {
    this.isLoading = true;
    let url =
      APP_URLS.v1.getEmployeeUserList + '?target=otj&need_offthejob_detail=yes';
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        this.statusList = data?.myUserList;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
