import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { tenantInfo } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-home-business',
  templateUrl: './home-business.component.html',
  styleUrls: ['./home-business.component.css']
})
export class HomeBusinessComponent implements OnInit {

  tenantInfo: any = tenantInfo;
  token:boolean=false;
  userDetail: any;
  student_Id = '';
  constructor(private route : ActivatedRoute,
 private userSvc:UserService) { }


  ngOnInit(): void {

    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;
    })

    var userData = localStorage.getItem('profilesummaryData');
    if( userData != undefined && typeof userData == 'string' ){
      this.userDetail = JSON.parse(userData);
      this.student_Id = this.userDetail._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.token=true

    }
  }

}
