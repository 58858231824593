import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { CommonService } from '../../services/common.service'
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-lms-inner-assignment',
  templateUrl: './lms-inner-assignment.component.html',
  styleUrls: ['./lms-inner-assignment.component.css']
})
export class LmsInnerAssignmentComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  @Input() assignmentsData: any;
  @Output() valueUpdate = new EventEmitter();
  showDialogComplaint: boolean = false;
  fileSize: any;
  fileName = "";
  showDownloads: boolean = false;
  ID: any;
  uuid: any;
  chapter_Id: any;
  studentId: any;
  assignment_files = [];
  studentcomments = ""
  reports = [];
  image: any
  description: any;
  Assignmentdeclaration : boolean = false;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  courseIdFromRoute:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private cms: CommonService,
    private userSvc: UserService,
    private toast: ToastrService,
    private sanitizer: DomSanitizer
  ) {
    var routeData = this.route.snapshot.params.id;
    var routeArry = routeData.split('-');
    if( routeArry.length ){
      this.courseIdFromRoute = routeArry[0];
    }

    this.service.getAssignment().subscribe(data => {
      if (data) {
        this.assignmentsData = data
        this.ngOnInit()
      }
    })

    this.ID = localStorage.getItem('courseId')
    this.uuid = localStorage.getItem('uuid')
    this.chapter_Id = localStorage.getItem('chapterID')
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
  }

  isShowResubmitBtn : boolean = false;
  isShowSubmit : boolean = false;
  reportSet(){

    this.assignmentsData.report.sort((a, b) => {
      return <any>new Date(b.test_submitted_date) - <any>new Date(a.test_submitted_date);
    });

    this.assignmentsData.report.forEach((x,i)=>{
      x['accordion'] = '#assignmentaccordion' + i
      x['accordionID'] = 'assignmentaccordion' + i
    })
    this.reports = this.assignmentsData.report;
    this.isShowResubmitBtn = ( this.reports.length ) ? true : false;
    this.isShowSubmit = ( this.reports.length ) ? false : true;
    this.reports.forEach(x=>{
      if(x.teacher_image){
        x['teacher_file']=""
        x.teacher_file=APP_URLS.imagePath + 'swm'+ '/' +x.teacher_image
      }
      x.assignment_files.forEach(y => {
        x['file']=""
        x['file']=APP_URLS.imagePath + x.file_path + '/' + y
      });
    })
  }

  ngOnInit(): void {
    this.reportSet();
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.assignmentsData.description);
  }
  handleFileInput(files) {
    this.fileSize = files[0].size / 1048576;
    if (this.fileSize > 12) {
      this.toast.error('Please upload below 12mb file');
      return;
    }

    const formData = new FormData();
    formData.append('images', files[0]);
    this.service.postCallImg(APP_URLS.assignmentUpload, formData)
      .subscribe((data: any) => {
        if(data.errorMessage)
        {
          this.toast.error(data.errorMessage)
        }  else if(data.message){
          this.fileName=""
          this.toast.error(data.message)
        } else {
          this.assignment_files = [];
          this.fileName = data[0].name
          this.assignment_files.push(this.fileName)
          this.toast.success('Your chosen file is attached successfully. Now add your comments, tick  declaration check box and submit assignment by clicking "Submit button"')
        }
        // this.assignment_files.push(this.fileName)
        // this.toast.success('Success')
      }, err => {
        this.toast.error(err.errorMessage)
      })
  }

  openPopup() {
    this.assignment_files = [];
    this.studentcomments = "";
    this.showDialogComplaint = true;
    this.Assignmentdeclaration = false;
  }

  getResult( num:any) {
    var uuid = localStorage.getItem('uuid');
    let url;
    if( this.courseIdFromRoute != '' ){
      url = APP_URLS.lmsInner + uuid + '&course=' + this.courseIdFromRoute
    } else {
      url = APP_URLS.lmsInner + uuid
    }

    this.service.getCall(url).subscribe((res: any) => {
      if( typeof res.chapters_a != 'undefined' ){
        this.assignmentsData = res.chapters_a.find(o => o.uuid == uuid);
        localStorage.setItem('lmsInnerData', JSON.stringify(res))
        this.reports = this.assignmentsData.report;
        this.service.setexam(num);
        this.reportSet();
      }
    });
  }

  showAttachments(){
    window.scroll(0,0);
    this.showDownloads = true

  }
  submitAssignment() {

    if(this.assignment_files.length == 0){
      this.service.showError("Please upload Attachment")
      return;
    }

    if (this.assignmentsData.assignment_approval == "PA") {
      var status = "UR";
    }
    if (this.assignmentsData.assignment_approval != "PA") {
      var status = "A";
    }

    let url = APP_URLS.lmsInnerSubmit
    let payload = {
      chapter: this.assignmentsData._id,
      student: this.studentId,
      course: this.ID,
      exam_type: this.assignmentsData.chapter_type,
      review_status: status,
      review_iv_status: status,
      test_submitted_date: new Date(),
      student_answer_date: new Date(),
      assignment_files: this.assignment_files,
      student_comments: this.studentcomments

    }

    this.userSvc.putCallwithHeaders(url, payload)
      .subscribe((res: any) => {
        if (res.respCode) {
          this.assignment_files = [];
          this.studentcomments = "";
          // this.cms.loaderSet(false);
          this.userSvc.showSuccess(res.respMessage);

          this.showDialogComplaint=false;
          this.uuid = localStorage.setItem('uuid',this.assignmentsData.uuid);
          this.getResult( this.assignmentsData.serialnum );
        }
        else {
          // this.cms.loaderSet(false);
          this.userSvc.showError(res.respMessage)
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('applicaion failed')
      })
  }

  submitLater() {
    this.userSvc.setAssignLater(true)
  }

}
