import { Component, OnInit } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-employer-download-center',
  templateUrl: './employer-download-center.component.html',
  styleUrls: ['./employer-download-center.component.css'],
})
export class EmployerDownloadCenterComponent implements OnInit {
  isLoading = false;
  details = [];
  singleDetail: any[] = [];
  projectDetails = [];
  singleProjectDetail: any;
  isProjectInfo: boolean = false;
  projectPopupOpen = 'none';
  fileSize: any;
  fileName: any;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

  totalItem = 0;
  page = 1;
  countPagination = 10;

  constructor(private profileSvc: MyProfileService) {}

  ngOnInit(): void {
    this.getDetails();
  }

  changePage(e) {
    if (!e) {
      e = 1;
    }
    this.page = e;
    this.getDetails();
  }

  getDetails() {
    this.isLoading = true;
    this.projectDetails = [];
    this.details = [];
    let url = APP_URLS.v1.downloadCenter;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.details = res.list;
        this.totalItem =
          this.page == 1 ? res?.count.totalRecords : this.totalItem;
        this.isLoading = false;
      },
      (err) => {}
    );
  }

  attachModalOpen(data) {
    let attach = [];
    data?.attributes?.attachments.forEach((data1) => {
      attach.push(data1);
    });
    this.singleDetail = attach;
  }

  attachModalClose(): void {
    this.singleDetail = [];
  }
}
