import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hrminfomat' })
export class HrMinFormat implements PipeTransform {
  transform(input) {
    if (!input) {
      input = '00:00';
      return '00:00';
    }
    input = parseFloat(input).toFixed(2);
    input = input.toString();
    var inputArr = input.split('.');
    if (typeof inputArr[0] != 'undefined') {
      if (inputArr[0].length == 1) {
        inputArr[0] = '0' + inputArr[0];
      }
      if (typeof inputArr[1] != 'undefined') {
        if (inputArr[1].length == 1) {
          inputArr[1] = '0' + inputArr[1];
        }
        var returnVal = inputArr[0] + ':' + inputArr[1];
      } else {
        var returnVal = inputArr[0] + ':00';
      }
      return returnVal;
    } else {
      return '00:00';
    }
  }
}
