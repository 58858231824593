import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'timeconverter',
})
export class TimeconverterPipe implements PipeTransform {
  transform(item: any) {
    if (item && String(item).length == 1) {
      return '0' + item;
    } else {
      return item;
    }
  }
}
