import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.css']
})
export class FeedbacksComponent implements OnInit {
  p = 1;
  countPagination = 10;
  studentId: any;
  EmpData = [];
  arrayData = [];
  feedBackData: any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number;
  showDialogFeedBack: boolean = false;
  feedback_id: any;
  feedbackpopupopen = 'none';
  rateoptions = [];
  queries = []
  courserating: number[] = [1, 2, 3, 4, 5];
  staffrating: number[] = [1, 2, 3, 4, 5];
  feedback_0: number[] = [1, 2, 3, 4, 5];
  queriesresponse = []

  feedbackSubmit = "no"
  isfeedbackDetail = [];
  feedback = {
    id: "",
    parent: "",
    chapterId: ""
  }
  upd_id = "";
  isNoFeedBck:boolean = false;
  List = [];
  
  constructor(private profileSvc: MyProfileService,
    public cms: CommonService,
    private toast: UserService) { }

  ngOnInit(): void {
    this.profileSvc.setSidemenuTab("feedback");
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
  
    // let data = JSON.parse(localStorage.getItem('feedbackData'))
    // if (!data) {
      this.getFeedBack();
    // }
    // else {
    //   this.feedBackData = data;
    
    // }
  }

  getFeedBack() {
    let url = APP_URLS.profileFeedback
    // this.cms.loaderSet(true);
    this.profileSvc.getCallWithHeaders(url)
    .subscribe((res: any) => {
      // this.cms.loaderSet(false);
      this.feedBackData = res.student_feedback.map((x,i)=>{
        if(x){
          x['sno'] = i + 1;
        }
       
        return x
      });
     
      localStorage.setItem('feedbackData', JSON.stringify(this.feedBackData))
  
      // this.feedBackData.forEach((y,i)=>{
      //   y['sno'] = i + 1;
      // })
      this.feedBackData.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
	this.isNoFeedBck = this.feedBackData.length ? false : true;
    })

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.List = pageOfItems;
  }
  feedbackPopupOpen(data) {
    var obj = {
      'aboutcourse': "",
      'aboutstaff': "",
      'courserating': "",
      'staffrating': "",
    }
    this.upd_id = data._id
    this.queries = []
    this.rateoptions = []
    this.isfeedbackDetail = []
    this.feedbackpopupopen = 'block';
    if (data) {
      this.rateoptions = data.bindVal.rateoptions
      this.queries = data.bindVal.queries
    }
    if (data.queriesresponse.length > 0) {
      data.queriesresponse.forEach((x) => {
        obj.aboutcourse = x.aboutcourse
        obj.aboutstaff = x.aboutstaff
        obj.courserating = x.courserating
        obj.staffrating = x.staffrating
        if (this.queries.length > 0) {
          this.queries.forEach((y, i) => {
            let value = "feedback_" + i
            Object.keys(x).filter(k => {
              if (k == value) {
                y['feedback'] = x[k]
                obj['feedback_' + i] = x[k]
              }
            }
            );
          })
        }
      });
    }
    else {
      obj.aboutcourse = ""
      obj.aboutstaff = ""
      obj.courserating = ""
      obj.staffrating = ""
      if (this.queries.length > 0) {
        this.queries.forEach((x, i) => {
          x['feedback'] = ""
        })
      }
    }
     this.queriesresponse=[]
    this.queriesresponse.push(obj)
  }
  feedbackPopupClose() {
    this.feedbackpopupopen = 'none'
  }
  feedBackStar(star, index) {
    if (this.queriesresponse.length > 0) {
      this.queriesresponse.forEach((x, i) => {
          var value = 'feedback_' + index
          Object.keys(x).filter(k => {
            if (k == value) {
              x[k]=star
            }
            else{
              x['feedback_'+index] = star
            }
          })
      })
    }
    if (this.queries.length > 0) {
      this.queries.forEach((x, i) => {
        if (index == i) {
          x['feedback'] = star
        }
      })
    }
  }

  staffStar(star) {
    this.queriesresponse[0]['staffrating'] = star;
  }
  courseStar(star) {
    this.queriesresponse[0]['courserating'] = star;
  }
  submitfeedback() {
    var payload = {
      "upd_id": this.upd_id,
      "queriesresponse": this.queriesresponse
    }
    // this.cms.loaderSet(true);
    let url = APP_URLS.profileFeedback
    this.profileSvc.putCall(url, payload)
      .subscribe((res: any) => {
        // this.cms.loaderSet(false);
        if (res.respCode == 202) {
          this.toast.showSuccess(res.respMessage)
          this.getFeedBack();
          this.feedbackPopupClose()
        }
        else {
          this.toast.showError(res.respMessage)
        }
      },
        err => {
          // this.cms.loaderSet(false);
          this.toast.showError('Failed')
        })
  }

}
