import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TenantId } from '../Models/url';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class CourseService {
  private subject = new Subject<any>();
  private heroesUrl = 'api/heroes';  
  popup: Subject<any>;
  profileSub:Subject<any>;
  updatepopup: Subject<any>;
  feedbackrefresh: Subject<any>;
 selected: Subject<any>;
  token = "";
  examcomplete:Subject<any>;
  AssignmentData:Subject<any>;
 ExamData:Subject<any>;
 openexam:Subject<any>;
  menucondition:Subject<any>;
  submitEvidencepopup:Subject<any>;
  cart:Subject<any>;
  BookforInterview:Subject<any>;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  selectCourseByIdData: any;

  headers: any;
  courceid = "";
  buttonType = ""
  constructor(private toastr: ToastrService, private http: HttpClient) {
    this.popup = new Subject<any>();
    this.updatepopup = new Subject<any>();
    this.selected = new Subject<any>();
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    this.examcomplete=new Subject<any>();
    this.AssignmentData=new Subject<any>();
    this.profileSub=new Subject<any>();
    this.feedbackrefresh = new Subject<any>();
    this.menucondition = new Subject<any>();
    this.ExamData = new Subject<any>();
    this.submitEvidencepopup = new Subject<any>();
    this.openexam = new Subject<any>();
    this.cart = new Subject<any>();
    this.BookforInterview = new Subject<any>(); 
  }

  showSuccess(message) {
    this.toastr.success(message)
  }

  showError(message) {
    this.toastr.error(message)
  }

  getCall(url) {
    return this.http.get(url)
  }
  postCall(url, payload) {
    return this.http.post(url, payload)
  }

  setexam(value) {
    this.examcomplete.next(value)
  }

  getexam() {
    return this.examcomplete.asObservable();
  }
  setSelectedData(value) {
    this.selected.next(value)
  }

  getSelectedData() {
    return this.selected.asObservable();
  }

  setAssignment(value) {
    this.AssignmentData.next(value)
  }

  getAssignment() {
    return this.AssignmentData.asObservable();
  }
  setExamtype(value) {
    this.AssignmentData.next(value)
  }

  getExamtype() {
    return this.AssignmentData.asObservable();
  }


  setProfile(value) {
    this.profileSub.next(value)
  }

  getProfile() {
    return this.profileSub.asObservable();
  }

  
  setopenexam(data){
    this.openexam.next(data)
  }

  getopenexam() {
    return this.openexam.asObservable();
  }



  selectedcourse(id, type) {
    this.courceid = id
    this.buttonType = type
    return this.courceid, this.buttonType
  }

  putCall(url, payload) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.put(url, payload, { headers })
  }
  postCallImg(url, payload) {

    return this.http.post(url, payload)
  }

  postCallwithHeaders(url, payload) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }

    return this.http.post(url, payload, { headers })
  }
  
  selectCourseById(data) {
    return this.selectCourseByIdData = data;
  }

  senddataToHeader(message: string) {
    this.subject.next({ text: message });
  }

  clearMessage() {
    this.subject.next();
  }

  getDataToHeader(): Observable<any> {
    return this.subject.asObservable();
  }

  setupdatepopup(value) {
    this.updatepopup.next(value)
  }

  getupdatepopup() {
    return this.updatepopup.asObservable();
  }
  setsubmitEvidencepopup(value) {
    this.submitEvidencepopup.next(value)
  }

  getsubmitEvidencepopup() {
    return this.submitEvidencepopup.asObservable();
  }

  setFeedback(value) {
    this.feedbackrefresh.next(value)
  }
  getFeedback() {
    return this.feedbackrefresh.asObservable();
  }



  getCallwithHeader(url) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    let headers = {
      'Access-Control-Allow-Headers': 'Authorization',
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "' + TenantId + '","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.get(url, { headers })
  }

  setmenu(value) {
    this.menucondition.next(value)
  }

  getmenu() {
    return this.menucondition.asObservable();
  }

  setcart(value) {
    this.cart.next(value)
  }

  getcart() {
    return this.cart.asObservable();
  }

  setBookforInterview(value){
    this.BookforInterview.next(value)
  }

  getBookforInterview() {
    return this.BookforInterview.asObservable();
  }

}
