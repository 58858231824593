import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
// import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-book-interview-new',
  templateUrl: './book-interview-new.component.html',
  styleUrls: ['./book-interview-new.component.css'],
})
export class BookInterviewNewComponent implements OnInit {
  p: number = 1;
  slotpopupopen = 'none';
  showDialogComplaint: boolean = false;
  weekdays = [];
  employeeList = [];
  selectedEmployee = '';
  Id: any;
  studentId: any;
  slotLoading: boolean = false;
  isEmployeeNotHavingSlot: boolean = false;
  isInterViewData: boolean = false;
  isLoading: boolean = false;

  bookInterview = {
    reminder_date: '',
    reminder_to_time: '',
    reminder_time: '',
    message: '',
    reminder: '',
    weekday: '',
  };

  slotallowcationpayload = {
    _id: '',
    status: '',
    studentId: '',
    uuid: '',
    name: '',
    index: 0,
    eventId: '',
  };

  slotAlreadyBooked: boolean = false;
  InterviewDetails = [];

  constructor(
    private profileSvc: MyProfileService,
    private toast: ToastrService,
    private cms: CommonService
  ) {}

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getEmployeeList();
    this.getDetails();
  }

  onChangeFunForEmp() {
    this.isEmployeeNotHavingSlot = false;
    this.slotLoading = true;
    this.bookInterview = {
      reminder_date: '',
      reminder_to_time: '',
      reminder_time: '',
      message: '',
      reminder: '',
      weekday: '',
    };
    this.getslots();
  }

  getEmployeeList() {
    this.employeeList = [];
    let url = APP_URLS.v1.getEmployeeList + '/' + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.myEmployee && res.myEmployee.length) {
        this.employeeList = res.myEmployee;
      }
    }),
      (err) => {
        // this.cms.loaderSet(false);
      };
  }

  async getDetails() {
    this.InterviewDetails = [];
    this.isLoading = true;
    let url = APP_URLS.bookForInterviewCalenderData;
    await this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        if (res) {
          if (!res.not_completed_event) {
            this.slotAlreadyBooked = false;
          } else {
            this.slotAlreadyBooked = true;
          }

          res.student_event.sort((val1, val2) => {
            return (
              new Date(val2.attributes.reminder_date).valueOf() -
              new Date(val1.attributes.reminder_date).valueOf()
            );
          });

          res.student_event.forEach((x, i) => {
            let obj2 = {
              serialnum: i + 1,
              reminder_date: x.attributes.reminder_date,
              reminder_time: x.attributes.reminder_time.slice(0, 5),
              reminder_to_time: x.attributes.reminder_to_time.slice(0, 5) || '',
              status: x.attributes.status || '',
              message: x.attributes.message || '',
              with_whom: x.assigned_employee.length ? x.assigned_employee : [],
            };

            this.InterviewDetails.push(obj2);
          });
        }
        this.isInterViewData = this.InterviewDetails.length ? false : true;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  openPopup() {
    if (this.bookInterview.reminder_date != '') {
      this.slotpopupopen = 'block';
    } else {
      if (this.slotAlreadyBooked) {
        this.toast.warning('Slot already booked');
      } else {
      }
    }
  }

  PopupClose() {
    this.slotpopupopen = 'none';
  }

  getslots() {
    this.weekdays = [];
    let url =
      APP_URLS.v1.bookForInterviewslotCreation +
      '?employeeRef=' +
      this.selectedEmployee;
    this.profileSvc.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.details && Object.keys(res.details).length) {
        this.Id = res.details._id;
        res.details.weekdays.forEach((x, i) => {
          x['data'] = x[x[Object.keys(x)[0]]];
          // if( x.date ){
          //   var momentVariable = moment(x.date, 'DD-MM-YYYY');
          //   x.date = momentVariable.format('DD-MM-YYYY');
          // }

          x.data.forEach((time, j) => {
            if (
              time.studentId != '' &&
              time.studentId != this.studentId &&
              time.status != 'open' &&
              time.status != 'no-show'
            ) {
              x.data[j].status = 'notAvailable';
            }
          });
          this.weekdays.push(x);
        });
        this.isEmployeeNotHavingSlot = this.weekdays.length ? false : true;
        this.slotLoading = false;
        // this.findBookedSlot();
      } else {
        this.slotLoading = false;
        this.isEmployeeNotHavingSlot = true;
      }
    }),
      (err) => {
        this.slotLoading = false;
        // this.cms.loaderSet(false);
      };
  }

  // findBookedSlot(){
  //   this.slotAlreadyBooked = false;
  //   this.weekdays.forEach(day => {
  //     day.data.forEach(time => {
  //       if( ( time.studentId != '' && time.studentId == this.studentId ) && ( time.status == 'confirmed' || time.status == 'completed' || time.status == 'notAvailable' || time.status == 'pending' || time.isStudentValid ) ){
  //         this.slotAlreadyBooked = true;
  //       }
  //     })
  //   })
  // }

  bookSlot(data, item, index, parentindex) {
    if (data.active == true) {
      let el = document.getElementsByClassName('selectSlotbtn');
      if (typeof el[0] != 'undefined') {
        el[0].classList.remove('selectSlotbtn');
      }

      let id = 'btn-' + index + '-' + parentindex;
      var element = document.getElementById(id);
      element.classList.add('selectSlotbtn');

      this.bookInterview.reminder_date = item.date;
      this.bookInterview.reminder_time = data.slotStartTime;
      this.bookInterview.reminder_to_time = data.slotEndTime;
      this.bookInterview.weekday = item.name;
      this.slotallowcationpayload = {
        _id: this.Id,
        status: 'pending',
        studentId: this.studentId,
        uuid: data.uuid,
        name: item.name,
        index: index,
        eventId: '',
      };
    }
  }

  // timeslot() {
  //   let url = APP_URLS.bookForInterviewslotAllocation;
  //   this.profileSvc.postCall(url, this.slotallowcationpayload)
  //     .subscribe((data: any) => {

  //       if (data.respCode) {
  //         this.submit()

  //       }
  //       if (data.errorCode) {
  //         this.toast.error('Try Again')
  //       }

  //     }, err => {
  //       // this.cms.loaderSet(false);
  //       this.toast.error('Error')
  //     })
  // }

  // submit() {
  //   // this.cms.loaderSet(true);
  //   if (this.bookInterview.reminder_date == undefined || this.bookInterview.reminder_date == "") {
  //     // this.cms.loaderSet(false);
  //     this.toast.error('error')
  //     return;
  //   }

  //   let url = APP_URLS.bookForInterview

  //   let payload = {
  //       message: this.bookInterview.message,
  //       reminder: this.bookInterview.reminder,
  //       reminder_date: this.bookInterview.reminder_date,
  //       reminder_time: this.bookInterview.reminder_time,
  //       reminder_to_time:this.bookInterview.reminder_to_time
  //   }

  //   this.profileSvc.postCall(url, payload)
  //     .subscribe((data: any) => {
  //       // this.cms.loaderSet(false);
  //       if (data.respCode) {
  //         this.toast.success('Success');
  //         this.bookInterview.message = ""
  //         this.bookInterview.reminder_date = "";
  //         this.bookInterview.reminder_time = "";
  //         this.PopupClose()
  //         this.getslots();
  //         this.getDetails();
  //         this.profileSvc.setbookappointment("data")
  //       }
  //       if (data.errorCode) {
  //         this.toast.error('Try Again')
  //       }

  //     }, err => {
  //       // this.cms.loaderSet(false);
  //       this.toast.error('Error')
  //     })

  // }

  timeslot() {
    // this.cms.loaderSet(true);
    if (
      this.bookInterview.reminder_date == undefined ||
      this.bookInterview.reminder_date == ''
    ) {
      // this.cms.loaderSet(false);
      this.toast.error('error');
      return;
    }

    let url = APP_URLS.bookForInterview;
    // let dateArry = ( this.bookInterview.reminder_date ) ? this.bookInterview.reminder_date.split('-') : [];
    // let reminder_date = (dateArry.length > 0 ) ? dateArry[2] + '-' + dateArry[1] + '-' + dateArry[0] : '';

    let payload = {
      assigned_employee: [this.selectedEmployee],
      message: this.bookInterview.message,
      reminder: this.bookInterview.reminder,
      reminder_date: this.bookInterview.reminder_date,
      reminder_time: this.bookInterview.reminder_time,
      reminder_to_time: this.bookInterview.reminder_to_time,
      slotId: this.Id,
      student_payload: this.slotallowcationpayload,
    };
    this.profileSvc.postCall(url, payload).subscribe(
      (data: any) => {
        // this.cms.loaderSet(false);
        if (data.respCode) {
          this.submit(data.details._id);
        }
        if (data.errorCode) {
          this.toast.error(data.errorMessage);
        }
      },
      (err) => {
        // this.cms.loaderSet(false);
        this.toast.error('Error');
      }
    );
  }

  submit(id: any) {
    let url = APP_URLS.bookForInterviewslotAllocation;
    this.slotallowcationpayload.eventId = id;
    this.profileSvc.postCall(url, this.slotallowcationpayload).subscribe(
      (data: any) => {
        if (data.respCode) {
          this.toast.success('Success');
          this.bookInterview.message = '';
          this.bookInterview.reminder_date = '';
          this.bookInterview.reminder_time = '';
          this.PopupClose();
          this.getslots();
          this.getDetails();
          this.profileSvc.setbookappointment('data');
        }
        if (data.errorCode) {
          this.toast.error('Try Again');
        }
      },
      (err) => {
        // this.cms.loaderSet(false);
        this.toast.error('Error');
      }
    );
  }
}
