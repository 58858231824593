import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-studywithus',
  templateUrl: './studywithus.component.html',
  styleUrls: ['./studywithus.component.css']
})
export class StudywithusComponent implements OnInit {
  tenantInfo:any = tenantInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
