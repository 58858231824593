import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-home-categories',
  templateUrl: './home-categories.component.html',
  styleUrls: ['./home-categories.component.css'],
})
export class HomeCategoriesComponent implements OnInit {
  CourseData = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private profileSvc: MyProfileService
  ) {}

  ngOnInit() {
    this.getCourseList();
    this.getCourseListNew();
  }

  getCourseList() {
    let url = APP_URLS.Categories;
    this.service.getCall(url).subscribe((res: any) => {
      var name = [];
      var id = '';
      var categories = Object.values(res.course_category_detail);
      categories.forEach((x: any) => {
        var obj = {
          course_type: '',
          description: '',
          courses: [],
        };
        const repetingdata = name.find(
          (z) => z == res.course_category_detail.course_type
        );
        if (repetingdata) {
        } else {
          obj.course_type = x.title;
          obj.description = x.description;

          x.course.forEach((y) => {
            if (x.title == y.course_type) {
              obj.courses.push({
                id: y._id,
                name: y.name,
                slug: y.slug,
              });
            }
          });
          this.CourseData.push(obj);
        }
      });
    });
  }
  getCourseListNew() {
    let url = APP_URLS.Categories;
    this.service.getCall(url).subscribe((res: any) => {});
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      'app/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }
}
