import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { tr } from 'date-fns/locale';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { CourseService } from '../../../services/course.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-return-otj-job-modal',
  templateUrl: './return-otj-job-modal.component.html',
  styleUrls: ['./return-otj-job-modal.component.css'],
})
export class ReturnOtjJobModalComponent implements OnInit {
  @ViewChild('frm', { static: true }) frm: NgForm;
  @ViewChild('sigPad') sigPad: { nativeElement: any };

  isSubmitted = false;
  files: NgxFileDropEntry[] = [];
  studentId: any;
  fileName = [];
  offthejobsObj: FormGroup;
  studentDetail: any = {};
  employerDetail: any = {};
  courseFullName = '';
  signtureImage = APP_URLS.FILE_PATH_LINODE;
  sigPadElement: any;
  context: any;
  isDrawing = false;
  isSigned = false;
  img = '';
  Signature = '';
  InputVar: ElementRef;
  @Input() courseDetails: any;
  @Input() otjDetails: any;

  acceptStatement: false;
  acceptSignature: false;
  isEmpReviewLength = 0;
  isLearnSubmitLength = 0;
  isEmpCommentsLength = 0;
  isEmployerCommentsLength = 0;
  isEmployerComments: Boolean = false;
  employerComments: any = {
    ratings: 0,
  };
  rating = 4;
  role = '';
  routeRecords: Subscription;
  seletedFiles = [];
  submitBtnDisabled: boolean = false;
  starRating: number[] = [1, 2, 3, 4, 5];

  get f() {
    return this.offthejobsObj.controls;
  }

  constructor(
    private profileSvc: MyProfileService,
    private toast: UserService,
    private fb: FormBuilder,
    private courseSvc: CourseService,
    private route: ActivatedRoute
  ) {
    this.role = localStorage.getItem('role');

    if (this.role == 'student') {
      this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
      var profileData = localStorage.getItem('profilesummaryData');
      if (profileData != undefined && typeof profileData == 'string') {
        this.studentDetail = JSON.parse(profileData);
      }
    }

    if (this.role == 'employer') {
      var profileEmployerData = localStorage.getItem('profilesummaryData');
      if (
        profileEmployerData != undefined &&
        typeof profileEmployerData == 'string'
      ) {
        this.employerDetail = JSON.parse(profileEmployerData);
      }
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getProfileDetails();
      });
    }

    var today = new Date();
    this.offthejobsObj = this.fb.group({
      hours: this.fb.control('', Validators.required),
      minutes: this.fb.control('', Validators.required),
      start_date:
        today.getFullYear() +
        '-' +
        ('0' + (today.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + today.getDate()).slice(-2),
      start_time: this.fb.control('', Validators.required),
      whatislearned: this.fb.control('', Validators.required),
      workActivityDoneComment: this.fb.control('', Validators.required),
      rating: this.fb.control(''),
      comment_date: this.fb.control(new Date()),
    });
    if (localStorage.getItem('role')) {
      this.role = localStorage.getItem('role');
    }
  }

  ngOnInit(): void {
    if (this.otjDetails?.employee_comments?.length > 0) {
      this.isEmpCommentsLength = this.otjDetails?.employee_comments?.length;
    } else {
      this.isEmpCommentsLength = 0;
    }

    if (this.otjDetails?.employee_reviews?.length > 0) {
      this.isEmpReviewLength = this.otjDetails?.employee_reviews?.length;
    } else {
      this.isEmpReviewLength = 0;
    }

    if (this.otjDetails?.learner_submit?.length > 0) {
      this.isLearnSubmitLength = this.otjDetails?.learner_submit?.length;
    } else {
      this.isLearnSubmitLength = 0;
    }

    if (this.otjDetails?.employer_comments?.length > 0) {
      this.isEmployerCommentsLength =
        this.otjDetails?.employer_comments?.length;
      this.employerComments =
        this.otjDetails?.employer_comments[this.isEmployerCommentsLength - 1];
      this.isEmployerComments = true;
    } else {
      this.isEmployerComments = false;
    }

    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.courseFullName = localStorage.getItem('TrainingFullName');
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
      },
      (err) => {}
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\:\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.isFileSizeAllowed(file.size)) {
            var fileExt = file.name.split('.').pop();
            var validExtensions = [
              'bmp',
              'doc',
              'docx',
              'gif',
              'jpeg',
              'jpg',
              'pdf',
              'rtf',
              'tif',
              'tiff',
              'txt',
              'xls',
              'xlsx',
              'ppt',
              'pptx',
              'png',
            ];

            if (validExtensions.indexOf(fileExt.toLowerCase()) == -1) {
              var err_notify =
                'Only formats are allowed : ' + validExtensions.join(', ');
              this.toast.showError(err_notify);
              return;
            }
            this.handleFileInput(file);
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public isFileSizeAllowed(size) {
    let isFileSizeAllowed = false;
    if (size < 1024 * 5000) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;
  }

  async handleFileInput(file) {
    
    this.submitBtnDisabled = true;

    if (this.seletedFiles.indexOf(file.name) == -1) {
      this.seletedFiles.push(file.name);
      const formData = new FormData();
      formData.append('images', file);
      await this.profileSvc
        .postCallImg(APP_URLS.fileUpload, formData)
        .subscribe(
          (data: any) => {
            this.submitBtnDisabled = false;
            if (data.errorMessage) {
              this.toast.showError(data.errorMessage);
            } else {
              if (data.length) {
                let obj = {
                  id: Math.random(),
                  actualName: file.name,
                  dbName: data[0].name,
                };
                this.fileName.push(obj);
              }

              this.toast.showSuccess('Success');
            }
          },
          (err) => {
            this.toast.showError('Error');
          }
        );
    } else {
      this.toast.showError('File Already Selected');
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  removeFile(file: any) {
    this.seletedFiles.splice(this.seletedFiles.indexOf(file.actualName), 1);
    this.fileName = this.fileName.filter((fi) => fi.id != file.id);
  }

  onSubmit() {
    this.isSubmitted = true;

    if (this.offthejobsObj.invalid) {
      return;
    }

    if (!this.otjDetails.acceptStudentDeclaration) {
      if (!this.acceptStatement) {
        this.toast.showError('Please accept declaration');
        return;
      }

      if (!this.acceptSignature) {
        this.toast.showError('Please accept signature');
        return;
      }

      if (!this.studentDetail.Signature) {
        this.toast.showError('Signature Required');
        return;
      }
    }

    this.submitBtnDisabled = true;

    let selectFile = [];
    this.fileName.forEach((file) => {
      let obj = {
        name: file.dbName,
      };
      selectFile.push(obj);
    });

    let url = APP_URLS.postOffTheJobs;

    var payload = {
      start_date: this.offthejobsObj.get('start_date').value,
      start_time: this.offthejobsObj.get('start_time').value,
      hours: this.offthejobsObj.get('hours').value,
      minutes: this.offthejobsObj.get('minutes').value,
      whatislearned: this.offthejobsObj.get('whatislearned').value,
      workActivityDoneComment: this.offthejobsObj.get('workActivityDoneComment')
        .value,
      attachments: selectFile,
      updateid: this.otjDetails._id,
      acceptStudentDeclaration: this.acceptStatement,
      acceptStudentSignature: this.acceptSignature,
      // record_id: this.otjDetails?.learner_submit[0]?._id || '',
    };

    this.courseSvc.postCallwithHeaders(url, payload).subscribe(
      (data: any) => {
        this.isSubmitted = false;
        this.submitBtnDisabled = false;
        this.toast.showSuccess('Success');
        this.closeModal();
      },
      (err) => {
        this.isSubmitted = false;
        this.submitBtnDisabled = false;
        this.closeModal();
        this.toast.showError('Error');
      }
    );
  }

  onSubmitEmployee() {
    this.isSubmitted = true;

    if (this.offthejobsObj.get('workActivityDoneComment').value == '') {
      this.toast.showError('Work activity done comment field mandatory');
      return;
    }

    if (this.offthejobsObj.get('rating').value == 0) {
      this.toast.showError('Rating field mandatory');
      return;
    }

    if (!this.otjDetails.acceptEmployerDeclaration) {
      if (!this.acceptStatement) {
        this.toast.showError('Please accept declaration');
        return;
      }

      if (!this.acceptSignature) {
        this.toast.showError('Please accept signature');
        return;
      }

      if (!this.employerDetail.signature) {
        this.toast.showError('Signature Required');
        return;
      }
    }

    this.submitBtnDisabled = true;

    let selectFile = [];
    this.fileName.forEach((file) => {
      let obj = {
        name: file.dbName,
      };
      selectFile.push(obj);
    });

    let url = APP_URLS.v1.commentorreviewsave;

    var payload = {
      commentorreview: 'employer_comment',
      updateData: {
        ratings: this.offthejobsObj.get('rating').value,
        comment_date: this.offthejobsObj.get('comment_date').value,
        comments: this.offthejobsObj.get('workActivityDoneComment').value,
        attachments: selectFile,
        declaration: this.acceptStatement,
      },

      findData: {
        _id: this.otjDetails._id,
      },
    };

    this.courseSvc.postCallwithHeaders(url, payload).subscribe(
      (data: any) => {
        this.isSubmitted = false;
        this.submitBtnDisabled = false;
        this.toast.showSuccess('Success');
        this.closeModal();
      },
      (err) => {
        this.isSubmitted = false;
        this.submitBtnDisabled = false;
        this.closeModal();
        this.toast.showError('Error');
      }
    );
  }

  reset() {
    this.frm.resetForm();
    if (this.InputVar?.nativeElement) {
      this.InputVar.nativeElement.value = '';
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    if (this.context) {
      this.context.clearRect(
        0,
        0,
        this.sigPadElement.width,
        this.sigPadElement.height
      );
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  save() {
    this.img = this.sigPadElement.toDataURL('image/png');
  }

  dosignature() {
    if (this.isSigned) {
      this.img = this.sigPadElement.toDataURL('image/png');
      this.submitBtnDisabled = true;
      if (
        this.img !=
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'
      ) {
        let url = APP_URLS.DoSignature;
        var Payload = {
          signature: this.img,
        };
        this.profileSvc.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.toast.showSuccess('Your signature is saved successfully');
              this.submitBtnDisabled = false;
            }
            if (this.role == 'student') {
              this.update();
            }

            if (this.role == 'employer') {
              this.updateEmployeeSignature();
            }
          }
          if (res.errorCode) {
            this.toast.showError(res.errorMessage);
          }
        });
      } else {
        this.toast.showError('Please draw your signature');
        this.submitBtnDisabled = false;
      }
    }
  }

  async updateEmployeeSignature() {
    let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    let Payload = {
      Signature: this.Signature || this.employerDetail.Signature,
    };

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.employerDetail.signature = this.Signature;
        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.employerDetail)
        );
        this.submitBtnDisabled = false;
      }

      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
      }
    });
  }

  async update() {
    let url = APP_URLS.profileDetails + this.studentId;
    let Payload = {
      Signature: this.Signature,
    };

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.studentDetail.Signature = this.Signature;
        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.studentDetail)
        );
        this.submitBtnDisabled = false;
      }
      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
        this.submitBtnDisabled = false;
      }
    });
  }

  closeModal() {
    this.profileSvc.closeModalPopup();
  }

  getNumber(num) {
    return new Array(num);
  }
}
