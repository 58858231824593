import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { CourseService } from '../../services/course.service';

@Component({
  selector: 'app-lms-inner-sidenav',
  templateUrl: './lms-inner-sidenav.component.html',
  styleUrls: ['./lms-inner-sidenav.component.css'],
})
export class LmsInnerSidenavComponent implements OnInit {
  FinalChaptersArray = [];
  menustatus = 'active';
  ID: any;
  uuid = '';
  chapter_Id: any;
  IsprviouesChapterCompleted: any;
  constructor(
    private router: Router,
    public service: CourseService,
    private userSvc: UserService
  ) {}

  ngOnInit(): void {
    localStorage.setItem('menustatus', this.menustatus);
    this.service.getSelectedData().subscribe((data) => {
      if (data || data == 0) {
        this.uuid = localStorage.getItem('uuid');
        this.openChapter('', data, '');
      }
    });
    this.service.getmenu().subscribe((data) => {
      this.menustatus = data;
    });
    this.chapter_Id = localStorage.getItem('parentID');
    this.uuid = localStorage.getItem('uuid');
    this.ID = localStorage.getItem('courseId');
    this.FinalChaptersArray = JSON.parse(localStorage.getItem('AllChapters'));
    this.FinalChaptersArray.forEach((x) => {
      x['collapse'] = '';
      if (this.chapter_Id == x.chapter_id) {
        x.collapse = 'collapse.in show';
      } else {
        x.collapse = 'collapse';
      }
      x.child.forEach((y, index) => {
        y['isactive'] = '';
      });
    });
  }
  openChapter(data, i, course, clickFrom = null) {
    let lmsData = JSON.parse(localStorage.getItem('lmsInnerData'));

    this.IsprviouesChapterCompleted = 'no';

    if (typeof course.child != 'undefined') {
      if (course.child.length > 0) {
        if (i == 0) {
          this.IsprviouesChapterCompleted = course.child[i].iscompleted;
        } else if (i > 0) {
          var val = i - 1;
          this.IsprviouesChapterCompleted = course.child[val].iscompleted;
        }
      }
    }
    var allowOpen = '';
    if (clickFrom == 'sidebar') {
      if (
        i == 0 ||
        this.IsprviouesChapterCompleted == 'yes' ||
        data.upload_requireornot == 'UL' ||
        data.chapter_type == 'chapter'
      ) {
        allowOpen = 'yes';
      } else {
        allowOpen = 'no';
      }
    }
    // if i click the main nav bar clickFrom value is null : Raja
    if (allowOpen == 'yes' || clickFrom == null) {
      if (data) {
        this.uuid = data.uuid;
        localStorage.setItem('parentuuid', data.parent);
        localStorage.setItem('courseheading', course.name);
        localStorage.setItem('uuid', data.uuid);
        localStorage.setItem('coursename', data.name);
        localStorage.setItem('chapterID', data.chapter_id);
        this.router.navigate([
          'app/Mychapter/',
          this.ID + '-' + data.uuid,
          data.name.replace(/\s/g, '-'),
        ]);
        this.service.setexam(i);
      }
      if (this.FinalChaptersArray) {
        this.FinalChaptersArray.forEach((x) => {
          x.child.forEach((y, index) => {
            if (y.uuid == this.uuid) {
              if (i == index) {
                y.isactive = 'active';
              } else {
                y.isactive = '';
              }
            } else {
              y.isactive = '';
            }
            if (lmsData.chapters_a.length > 0) {
              lmsData.chapters_a.forEach((z) => {
                if (y.uuid == z.uuid) {
                  y.iscompleted = z.iscompleted;
                }
              });
            }
          });
        });
      }
    } else if (allowOpen == 'no') {
      this.userSvc.showError(
        'You must complete the previous section and get Approval'
      );
    }
  }
}
