import { Component, AfterViewInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-home-companies',
  templateUrl: './home-companies.component.html',
  styleUrls: ['./home-companies.component.css']
})
export class HomeCompaniesComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    $('.companies').owlCarousel({
      autoPlay: 3000,
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      responsive: {
          0: {
              items: 1
          },
          450: {
              items: 2
          },
          600: {
              items: 3
          },
          1000: {
              items: 6
          }
      }
  });
  }
}
