import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-skill-scan-table',
  templateUrl: './skill-scan-table.component.html',
  styleUrls: ['./skill-scan-table.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SkillScanTableComponent implements OnInit {
  skillScanDetails: any[] = [];
  formalReviewDetails: any[] = [];

  isLoading = false;

  singleSkillScanDetail: any;
  displayName = '';
  studentId = '';
  role: string;
  routeRecords: Subscription;

  learner_comments = '';
  student_score = '';
  employer_comments = '';
  employer_score = '';
  studentDetail: any = {};

  constructor(
    private route: ActivatedRoute,
    public service: CourseService,
    private profileSvc: MyProfileService,
    private toast: UserService
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getSkillScanReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getSkillScanReport();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {
    this.getEmployerorganisationDetails();
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  empOrgDetails: any = [];
  getEmployerorganisationDetails(): void {
    let obj = {
      _id: this.studentId,
    };
    let url = APP_URLS.getEmpOrganisation;
    this.service.postCall(url, obj).subscribe((results: any) => {
      if (results && results.details && results.details.length > 0) {
        this.empOrgDetails = results.details[0];
      }
    });
  }
  getSkillScanReport(): void {
    this.isLoading = true;
    let url = APP_URLS.v1.skillScanReport + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data?.sprReport?.length > 0) {
          this.skillScanDetails = data?.sprReport;
        } else {
          this.skillScanDetails = [];
        }

        if (data?.formalReviewDetails?.length > 0) {
          this.formalReviewDetails = data?.formalReviewDetails;
        } else {
          this.formalReviewDetails = [];
        }
      },
      (err) => {
        this.isLoading = false;
        this.skillScanDetails = [];
        this.formalReviewDetails = [];
      }
    );
  }

  openEditSKillScanModal(data): void {
    this.learner_comments =
      data?.skilScanReviewInfo?.skilScanReview?.learner_comments;
    this.student_score =
      data?.skilScanReviewInfo?.skilScanReview?.student_score;
    this.employer_comments =
      data?.skilScanReviewInfo?.skilScanReview?.employer_comments;
    this.singleSkillScanDetail = undefined;
    this.singleSkillScanDetail = data;
    this.employer_score =
      data?.skilScanReviewInfo?.skilScanReview?.employer_score;
  }

  closeEditSKillScanModal(): void {
    this.singleSkillScanDetail = undefined;
  }

  getReviewScore(id, skillScan) {
    if (skillScan?.skilScanReviewInfo?.skilScanReview) {
      const { review_score } = skillScan.skilScanReviewInfo.skilScanReview;
      let rs = '';
      review_score.forEach((review) => {
        if (review._id == id) {
          rs = review.monthly_reviews_score;
        }
      });
      return rs;
    }
  }

  updateSkill(skillInfoId, id) {
    if (this.role == 'student' && !this.student_score) {
      this.toast.showError('Please fill mandatory fields');
      return;
    }

    if (this.role == 'student' && !this.learner_comments) {
      this.toast.showError('Please fill mandatory fields');
      return;
    }

    if (this.role == 'employer' && !this.employer_comments) {
      this.toast.showError('Please fill mandatory fields');
      return;
    }

    let url = '';
    let payload = {
      skill_scan_activity_ref: id,
      skilScanReview: {
        action_plan: skillInfoId?.skilScanReview?.action_plan,
        review_score: skillInfoId?.skilScanReview?.review_score,
      },
      students: this.studentId,
    };

    if (this.role == 'student') {
      payload['reviewedByStudent'] = true;
      payload.skilScanReview['student_score'] = this.student_score;
      payload.skilScanReview['learner_comments'] = this.learner_comments;
    } else {
      payload['reviewedByEmployer'] = true;
      payload.skilScanReview['employer_comments'] = this.employer_comments;
      payload.skilScanReview['employer_score'] = this.employer_score;
    }

    if (skillInfoId?._id) {
      payload['_id'] = skillInfoId?._id;
      url = APP_URLS.v1.skillScanUpdate;
    } else {
      url = APP_URLS.v1.skillScanCreate;
    }

    this.service.postCallwithHeaders(url, payload).subscribe((data: any) => {
      if (data.toast.type == 'success') {
        this.getSkillScanReport();
        this.toast.showSuccess(data?.toast?.message);
        document.getElementById('closeSkillEditModal').click();
      } else {
        this.toast.showError(data?.toast?.message);
      }
    });
  }
}
