import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';
@Component({
  selector: 'app-whyus',
  templateUrl: './whyus.component.html',
  styleUrls: ['./whyus.component.css']
})
export class WhyusComponent implements OnInit {
  company=tenantInfo.name;
  constructor() { }

  ngOnInit(): void {
  }

}
