import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { TenantId } from '../../Models/url';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  tenantInfo: any = tenantInfo;
  company=tenantInfo.name;
  CourseData = [];
  access_token: boolean = false;
  subscribe = {
    email: '',
  };
  userRole = '';
  constructor(
    private router: Router,
    public service: CourseService,
    private userSvc: UserService
  ) {
    this.userSvc.getIsLogin().subscribe((data) => {
      this.userRole = localStorage.getItem('role') || 'student';
      if (data) {
        this.access_token = true;
      } else {
        this.access_token = false;
        if (!this.CourseData.length) this.getCourseList();
      }
    });
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('role') || 'student';
    if (this.userRole == 'student') {
      this.getCourseList();
    }

    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data != null) {
      this.access_token = true;
    }
  }

  getCourseList() {
    let url = APP_URLS.Courses;
    this.service.getCall(url).subscribe((res: any) => {
      var name = [];
      var id = '';
      res.courses.forEach((x) => {
        var obj = {
          course_type: '',
          id: '',
          courses: [],
        };
        const repetingdata = name.find((z) => z == x.course_type);
        if (repetingdata) {
        } else {
          obj.course_type = x.course_type;
          obj.id = x._id;
          name.push(x.course_type);
          id = x._id;
          res.courses.forEach((y) => {
            if (y.course_type == x.course_type) {
              obj.courses.push({
                id: y._id,
                name: y.name,
                slug: y.slug,
              });
            }
          });
          this.CourseData.push(obj);
        }
      });
    });
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      'app/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }

  submit() {
    if (this.subscribe.email == '') {
      this.userSvc.showError('Please Enter Email');
      return;
    }
    let payload = {
      email: this.subscribe.email,
      first_name: this.subscribe.email.split('@')[0],
      last_name: this.subscribe.email.split('@')[0],
      from_place: 'newsletter',
    };
    let url = APP_URLS.newlatter;

    this.userSvc.postCall(url, payload).subscribe((data: any) => {
      if (data.respCode) {
        this.subscribe.email = '';
        this.userSvc.showSuccess(data.respMessage);
      } else {
        this.userSvc.showError(data.respMessage);
      }
    });
  }

  home() {
    this.router.navigate(['/home']);
    //setTimeout(() => { location.reload(); }, 100);
  }
}
