import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-home-content-cards',
  templateUrl: './home-content-cards.component.html',
  styleUrls: ['./home-content-cards.component.css']
})
export class HomeContentCardsComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
