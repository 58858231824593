import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TenantId } from '../../Models/url';
declare let $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  showDialog = false;
  showDialogForgot = false;
  showDialogReg = false;
  sub: any;
  page: number;
  headerClass = '';
  selectCourseId: any;
  selectCourseIdData: any;
  token: boolean = false;

  CourseData = [];

  background_image = '';
  userDetail: any;
  CartCount = 0;
  CartList = [];
  student_Id = '';
  detailRecivedSubscription: Subscription;
  permissionData: any;
  myAtLaBShow: string = '';
  myOTJShow: string = '';
  profilePermission: any;
  userRole = '';

  ishomeText = 'header';
  ishome = true;
  isEnableTab = 'home';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private profileSvc: MyProfileService
  ) {
    this.background_image = '../../../assets/images/banner.png';

    this.router.events.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        var url = event.url.split('/').pop();
        console.log(url);
        if (url == 'app' || url == '') {
          this.isEnableTab = 'home';
          this.ishome = true;
          this.ishomeText = 'header';
          return;
        } else if (url == 'aboutus') {
          this.isEnableTab = 'aboutus';
          this.ishome = true;
          this.ishomeText = 'header';
          return;
        } else {
          this.ishome = false;
          this.ishomeText = '';
          if (url == 'emp-profile-summary') {
            this.isEnableTab = 'employer-profile';
          } else if (url == 'profile-summary') {
            this.isEnableTab = 'profile';
          } else {
            this.isEnableTab = url;
          }
        }
      }
    });

    profileSvc.getPermissionObsData().subscribe((res) => {
      this.ngOnInit();
      this.myOTJShow = res?.my_otj;
      this.myAtLaBShow = res?.my_at_lab;
    });

    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
    this.detailRecivedSubscription = this.userSvc
      .getCart()
      .subscribe((data) => {
        if (data) {
          this.ngOnInit();
        }
      });
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.student_Id = this.userDetail._id;
      }
    }

    if (this.userRole != 'employer') {
      let dataofcart = localStorage.getItem('cartdata');
      this.CourseData = [];
      this.CartList = [];
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
        // this.addtocartCall();
      }
    }

    this.profileSvc.getForgotPop().subscribe((data: any) => {
      if (data == 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data == 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data == 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.profileSvc.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.getIsLogin().subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.student_Id) {
      this.token = true;
    }

    this.selectCourseId = this.service
      .getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });

    this.sub = this.route.queryParams.subscribe((params) => {
      this.page = +params['page'] || 0;
      if (this.page == 0) {
        this.headerClass = 'header';
      } else if (this.page == 1) {
        this.headerClass = 'header inner-ban bg-imag-abt';
      } else if (this.page == 2) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 3) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 4) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 5) {
        this.headerClass = 'header inner-ban';
      }
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.top-header').addClass('top-fix');
          } else {
            $('.top-header').removeClass('top-fix');
          }
        });
      });
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.banner-carousel').addClass('padding-set-scroll');
          } else {
            $('.banner-carousel').removeClass('padding-set-scroll');
          }
        });
      });
    });
    if (this.userRole != 'employer') {
      this.getCourseList();
      this.getCart();
    }
  }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  logout() {
    localStorage.clear();
    this.token = false;
    this.userRole = '';
    this.userSvc.setIsLogin(false);
    this.router.navigate(['app']);
  }

  getCourseList() {
    let url = APP_URLS.Courses;
    this.service.getCall(url).subscribe((res: any) => {
      var name = [];
      var id = '';
      res.courses.forEach((x) => {
        if (x.course_type) {
          var obj = {
            course_type: '',
            id: '',
            courses: [],
          };
          const repetingdata = name.find((z) => z == x.course_type);
          if (repetingdata) {
          } else {
            obj.course_type = x.course_type;
            obj.id = x._id;
            name.push(x.course_type);
            id = x._id;
            res.courses.forEach((y) => {
              if (y.course_type == x.course_type) {
                obj.courses.push({
                  id: y._id,
                  name: y.name,
                  slug: y.slug,
                });
              }
            });
            this.CourseData.push(obj);
          }
        }
      });
      console.log(this.CourseData);
    });
  }

  openCourses(event, coursetype) {
    event.stopPropagation();
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      'app/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
    event.preventDefault();
  }

  openIndividulaCourse(event, course) {
    event.stopPropagation();
    localStorage.setItem('Individualcourse', course.slug);
    //  let name = course.slug.split(" "+"(");
    this.router.navigate(['app/' + course.slug]);
    event.preventDefault();
  }

  mywork() {
    if (this.userRole == 'employer') {
      this.router.navigate(['app/employer-mywork/emp-my-project']);
    } else {
      this.router.navigate(['app/student-my-work/student-work-summary']);
    }
  }

  home() {
    this.router.navigate(['app']);
    // setTimeout(() => { location.reload(); }, 300);
  }

  openCart() {
    this.router.navigate(['app/Cart']);
  }

  getCart() {
    if (localStorage.getItem('token') && this.student_Id) {
      let url = APP_URLS.getcart;
      let payload = {};
      this.userSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
        this.CartCount = res.listCount;
      });
    } else {
      let dataofcart = localStorage.getItem('cartdata');
      this.CartCount = 0;
      if (dataofcart != null) {
        this.CartList = dataofcart.split(',');
        this.CartCount = this.CartList.length;
      }
    }
  }

  async getProfilePermissions() {
    let url = APP_URLS.profilePermissions;

    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.profileSvc.setPermissionsObsData(res);
        this.myOTJShow = res?.my_otj;
        this.myAtLaBShow = res?.my_at_lab;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  tabChange(data) {
    if (data == 'home') {
      this.ishome = true;
      this.ishomeText = 'header';
      this.isEnableTab = data;
      return;
    }

    if (data == 'aboutus') {
      this.ishome = true;
      this.ishomeText = 'header';
      this.isEnableTab = data;
      return;
    }

    this.ishome = false;
    this.isEnableTab = data;
    this.ishomeText = '';
  }
}
