import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-progression-spr',
  templateUrl: './progression-spr.component.html',
  styleUrls: ['./progression-spr.component.css'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
})
export class ProgressionSprComponent implements OnInit {
  isLodingSkill = false;
  isLodingSkillLength = 6;
  isLoadingMonth = false;
  isLoadingFormal = false;

  skillScanDetails: any[] = [];
  formalReviewDetails: any[] = [];

  skillProgressDetails: any[] = [];
  monthlyProgressDetails: any[] = [];
  formalProgressDetails: any[] = [];

  isLoading = false;

  studentId = '';
  role: string;
  routeRecords: Subscription;
  displayName = '';
  studentDetail: any = {};

  constructor(
    private route: ActivatedRoute,
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService,
    private datePipe: DatePipe
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getSkillScanReport();
      this.getMonthlyReport();
      this.getFormalReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getSkillScanReport();
        this.getMonthlyReport();
        this.getFormalReport();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {}

  getReviewScore(id, skillScan) {
    if (skillScan?.skilScanReview) {
      const { review_score } = skillScan.skilScanReview;
      let rs = {};
      review_score.forEach((review) => {
        if (review._id == id) {
          rs = review;
        }
      });
      return rs;
    }
  }
  skilScanInfo = [];
  allCOlAveragae = [];
  monthlyReviewDetails = [];

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  getMonthlyReport(): void {
    this.monthlyReviewDetails = [];
    this.isLoadingMonth = true;
    let url = APP_URLS.v1.monthlyReview + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoadingMonth = false;
        var monthlyDetails =
          data?.reviewDetails?.length > 0 ? data?.reviewDetails : [];

        if (monthlyDetails?.length > 0) {
          var overAllProgress = 0;
          monthlyDetails.forEach((monthly) => {
            this.monthlyReviewDetails.push({
              key: this.datePipe.transform(
                monthly.monthlyReview.reviewdate,
                'dd-MM-yyyy'
              ),
              progress: monthly?.monthlyReview?.indicativeprogress
                ? parseFloat(monthly.monthlyReview.indicativeprogress)
                : 0,
            });
            if (monthly.monthlyReview.indicativeprogress) {
              overAllProgress =
                overAllProgress +
                parseFloat(monthly.monthlyReview.indicativeprogress);
            }
          });
          var overAllProgressAvg = 0;
          if (overAllProgress) {
            overAllProgressAvg =
              overAllProgress / this.monthlyReviewDetails.length;
          }
          this.monthlyReviewDetails.push({
            key: 'Overall',
            progress: overAllProgressAvg,
          });
        } else {
          this.monthlyReviewDetails = [];
        }
      },
      (err) => {
        this.isLoadingMonth = false;
        this.monthlyReviewDetails = [];
      }
    );
  }
  formalReviewInfo = [];
  getFormalReport(): void {
    this.isLoadingFormal = true;
    this.formalReviewInfo = [];
    let url = APP_URLS.v1.formalReview + '/' + this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoadingFormal = false;
        var formalDetails =
          data?.reviewDetails?.length > 0 ? data?.reviewDetails : [];

        if (formalDetails?.length > 0) {
          var overAllProgress = 0;
          formalDetails.forEach((formal) => {
            this.formalReviewInfo.push({
              key: this.datePipe.transform(
                formal.formalReview.reviewdate,
                'dd-MM-yyyy'
              ),
              progress: formal?.formalReview?.indicativeprogress
                ? parseFloat(formal.formalReview.indicativeprogress)
                : 0,
            });
            if (formal.formalReview.indicativeprogress) {
              overAllProgress =
                overAllProgress +
                parseFloat(formal.formalReview.indicativeprogress);
            }
          });
          var overAllProgressAvg = 0;
          if (overAllProgress) {
            overAllProgressAvg = overAllProgress / this.formalReviewInfo.length;
          }
          this.formalReviewInfo.push({
            key: 'Overall',
            progress: overAllProgressAvg,
          });
        } else {
          this.formalReviewInfo = [];
        }
      },
      (err) => {
        this.isLoadingFormal = false;
        this.formalReviewInfo = [];
      }
    );
  }

  getSkillScanReport(): void {
    this.isLodingSkill = true;
    this.allCOlAveragae = [];
    this.skilScanInfo = [];
    let url = APP_URLS.v1.skillScanReport + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLodingSkill = false;
        this.skillScanDetails =
          data?.sprReport?.length > 0 ? data?.sprReport : [];

        this.skilScanInfo = [];
        this.formalReviewDetails =
          data?.formalReviewDetails?.length > 0
            ? data?.formalReviewDetails
            : [];

        if (this.formalReviewDetails?.length > 0) {
          this.isLodingSkillLength = this.formalReviewDetails?.length + 2;
          this.skillScanDetails.forEach((element) => {
            element.standards.forEach((stdele) => {
              var reviewDate = [];
              this.formalReviewDetails.forEach((formal) => {
                var data = this.getReviewScore(
                  formal._id,
                  element.skilScanReviewInfo
                );
                if (data != undefined) {
                  if (!Object.keys(data).length) {
                    data = {
                      monthly_reviews_score: 0,
                    };
                  }
                  reviewDate.push(data);
                }
              });
              var overAllCount = 0;
              reviewDate.forEach((date) => {
                overAllCount = overAllCount + date.monthly_reviews_score;
              });
              var rowAverage = 0;
              if (overAllCount) {
                rowAverage = overAllCount / reviewDate.length;
              }
              this.skilScanInfo.push({
                standard_name: stdele.competencestandard_title,
                reviewInfo: reviewDate,
                rowAverage: rowAverage,
              });
            });
          });

          this.allCOlAveragae = [];
          for (var i = 0; i < this.formalReviewDetails.length; i++) {
            var score = 0;
            this.skilScanInfo.forEach((date) => {
              if (
                date.reviewInfo &&
                date.reviewInfo[i] &&
                date.reviewInfo[i].monthly_reviews_score
              ) {
                score = score + date.reviewInfo[i].monthly_reviews_score;
              }
            });
            var colAverage = 0;
            if (score) {
              colAverage = score / this.skilScanInfo.length;
            }
            this.allCOlAveragae.push({
              date: this.datePipe.transform(
                this.formalReviewDetails[i].formalReview.reviewdate,
                'dd-MM-yyyy'
              ),
              average: colAverage,
            });
          }

          var overAllSkillProgress = 0;
          this.skilScanInfo.forEach((avrdate) => {
            if (avrdate.rowAverage) {
              overAllSkillProgress = overAllSkillProgress + avrdate.rowAverage;
            }
          });

          var overAllSkillProgressAvg = 0;
          if (overAllSkillProgress) {
            overAllSkillProgressAvg =
              overAllSkillProgress / this.skilScanInfo.length;
          }
          this.allCOlAveragae.push({
            date: 'Over all',
            average: overAllSkillProgressAvg,
          });
        } else {
          this.formalReviewDetails = [];
        }
      },
      (err) => {
        this.isLoading = false;
        this.skillScanDetails = [];
        this.formalReviewDetails = [];
      }
    );
  }
}
