import { Pipe} from "@angular/core";

@Pipe({
  name:'FileFilter'
})

export class FileFilterPipe{
  checking(filetype:any,cases:any){
    switch(cases){

    case 1:{
      if(filetype!="application/pdf"||"audio/mpeg"){
        return "false";
      }
      break;
    }
    case 2:{
      if(filetype!="application/pdf"||"application/xslx"){
        return false;
      }
      break;
    }
    default:
      {
        return true;
      }
    }
  }

}
