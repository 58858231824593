import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-howtoapply',
  templateUrl: './howtoapply.component.html',
  styleUrls: ['./howtoapply.component.css']
})
export class HowtoapplyComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  showDialogReg = false;

  constructor() { }

  ngOnInit(): void {
  }

  openRegister(){
this.showDialogReg = true;
  }
}
