import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-offthejobs',
  templateUrl: './offthejobs.component.html',
  styleUrls: ['./offthejobs.component.css'],
})
export class OffthejobsComponent implements OnInit {
  max = 'max';
  p = 1;
  countPagination = 10;
  courseId: any;
  studentId: any;
  AllOffthejobs: any;
  editpopup: boolean = false;
  editPopupOpen = 'none';
  showDialogOffthejob: boolean = false;
  showDialogEditOffthejob: boolean = false;
  showReturnOTJDialog: boolean = false;
  totalItems: any;
  @Input() courseDetails: any;
  editData: any;
  SubjectCategeory = [
    { id: 1, value: 'Attendance at College/Centre' },
    { id: 2, value: 'Learning activity/Assessment(Project)' },
    { id: 3, value: 'Behaviour and Skills Workshops' },
    { id: 4, value: 'Competition' },
    { id: 5, value: 'In-house CPD' },
    { id: 6, value: 'Mentoring' },
    { id: 7, value: 'Online Learning' },
    { id: 8, value: 'Reflective Account' },
    { id: 9, value: 'Research online /offline for the projects' },
    { id: 10, value: 'Skype/ Conference Call/ Zoom' },
    { id: 11, value: 'Synoptic Project' },
    { id: 12, value: 'Work Shadowing' },
    { id: 13, value: 'Register Session' },
  ];

  Training = [
    { id: 1, value: 'Main Provider' },
    { id: 2, value: 'Training Provider' },
    { id: 3, value: 'Employer' },
    { id: 4, value: 'All Parties' },
    { id: 5, value: 'External' },
  ];
  offthejobsObj = {
    subcategory: '',
    category: '',
    attachment: '',
    hours: '',
    minutes: '',
    start_date: '',
    start_time: '',
    student: '',
    whatislearned: '',
  };
  offthejobsDetail = {
    otjMinutes: '',
    otjhours: '',
    targetOffTheJobhours: '',
    targetjob: '',
    totalHours: '',
    total_hours: '',
    totaloffthejob: '',
  };
  st;
  fileSize: any;
  fileName: any;
  editId: any;
  onlineLearningCount = 0;
  MentoringCount = 0;
  CentreCount = 0;
  houseCPDCount = 0;
  totalHours = 0;
  dialogSize = 'max';
  detailOtjReords: Subscription;
  addOtjRecords: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService
  ) {
    this.courseId = localStorage.getItem('courseId');
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;

    this.detailOtjReords = profileSvc
      .closeModalPopupService()
      .subscribe((res) => {
        this.showReturnOTJDialog = false;
        this.showAcceptOTJDialog = false;
        this.getOfftheJobs();
      });

    this.addOtjRecords = profileSvc.getPopup().subscribe((data) => {
      this.getOfftheJobs();
    });
  }

  ngOnInit(): void {
    this.getOfftheJobs();
  }

  ngOnDestroy() {
    this.detailOtjReords.unsubscribe();
    this.addOtjRecords.unsubscribe();
  }

  getOfftheJobs() {
    let url = APP_URLS.getOffTheJobs + this.courseId + '/' + this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.AllOffthejobs = data?.offthejob;
        this.totalItems = this.AllOffthejobs?.length;

        this.offthejobsDetail = data.offthejob_detail;
        if( this.AllOffthejobs ){
          this.AllOffthejobs.forEach((x) => {
            if (x.category == 'In-house CPD') {
              this.houseCPDCount = this.houseCPDCount + 1;
            }
            if (x.category == 'Attendance at College/Centre') {
              this.CentreCount = this.CentreCount + 1;
            }
            if (x.category == 'Online Learning') {
              this.onlineLearningCount = this.onlineLearningCount + 1;
            }
            if (x.category == 'Mentoring') {
              this.MentoringCount = this.MentoringCount + 1;
            }
            if (x.hours) {
              this.totalHours += parseInt(x.hours);
            }
            if (x.created_by && x.created_by.student) {
              x.recordUplodName = x.created_by.student.displayName;
            } else if (x.created_by_select && x.created_by_select.employee) {
              x.recordUplodName = x.created_by_select.employee.name;
            }

            if (!x.status || x.status == '') {
              x.status = 'Under Review';
            }

            x.final_learner_submit = {};
            if (x.learner_submit && x.learner_submit.length) {
              if (x.learner_submit[x.learner_submit.length - 1]) {
                x.final_learner_submit =
                  x.learner_submit[x.learner_submit.length - 1];
              }
            }

            x.final_employee_reviews = {};
            if (x.employee_reviews && x.employee_reviews.length) {
              if (x.employee_reviews[x.employee_reviews.length - 1]) {
                x.final_employee_reviews =
                  x.employee_reviews[x.employee_reviews.length - 1];
              }
            }
          });
        }
      },
      (err) => {}
    );
  }
  handleFileInput(files) {
    this.fileSize = files[0].size;
    const formData = new FormData();

    formData.append('images', files[0]);
    this.profileSvc.postCallImg(APP_URLS.fileUpload, formData).subscribe(
      (data: any) => {
        if (data.errorMessage) {
          this.toast.showError(data.errorMessage);
        }
        (this.fileName = data[0].name),
          (this.offthejobsObj.attachment = this.fileName),
          this.toast.showSuccess('Success');
      },
      (err) => {
        this.toast.showError('Error');
      }
    );
  }
  showAcceptOTJDialog: boolean = false;
  otjReturnObj = {};
  otjAcceptObj = {};
  editPopup(data) {
    // this.editId = data._id;
    // this.editpopup = true;
    // if (this.editpopup == true) {
    //   this.editPopupOpen = 'block';
    //   (this.offthejobsObj.attachment = data.attachment),
    //     (this.offthejobsObj.category = data.category),
    //     (this.offthejobsObj.subcategory = data.subcategory),
    //     (this.offthejobsObj.hours = data.hours),
    //     (this.offthejobsObj.minutes = data.minutes),
    //     (this.offthejobsObj.start_date = data.start_date),
    //     (this.offthejobsObj.start_time = data.start_time),
    //     (this.offthejobsObj.student = data.student),
    //     (this.offthejobsObj.whatislearned = data.whatislearned);
    // } else {
    //   this.editPopupOpen = 'none';
    // }
    this.otjReturnObj = data;
    this.showReturnOTJDialog = true;
  }

  acceptPopup(data) {
    this.otjAcceptObj = data;
    this.showAcceptOTJDialog = true;
  }

  editPopupClose() {
    this.editpopup = false;
    if (this.editpopup == false) {
      this.editPopupOpen = 'none';
    } else {
      this.editPopupOpen = 'block';
    }
  }

  updateData() {
    let url = APP_URLS.postOffTheJobs + '/' + this.editId;
    this.service.postCallwithHeaders(url, this.offthejobsObj).subscribe(
      (data: any) => {
        this.toast.showSuccess('Success');
        this.getOfftheJobs();
        this.editPopupClose();
      },
      (err) => {
        this.toast.showError('Error');
      }
    );
  }
}
