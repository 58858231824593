import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { TrainingService } from '../../services/training.service';
import { UserService } from '../../services/user.service';
import { TenantId } from '../../Models/url';

@Component({
  selector: 'app-favourite-trainings',
  templateUrl: './favourite-trainings.component.html',
  styleUrls: ['./favourite-trainings.component.css'],
})
export class FavouriteTrainingsComponent implements OnInit {
  student_Id: any;
  TrainingList = [];
  showDialogReg = false;
  applyButton = '';
  fileUrl = APP_URLS.FILE_PATH_LINODE;

  constructor(public service: TrainingService, private userSvc: UserService) {}

  ngOnInit(): void {
    this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getTrainingList();
  }
  getTrainingList() {
    let url = APP_URLS.FavourateTraining;
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.favorites.length > 0) {
        res.favorites.forEach((x) => {
          if (x.course.applied == false) {
            this.TrainingList.push(x);
          }
        });
      }
    });
  }
  AddFav(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';
    if (this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.userSvc.showSuccess(data.respMessage);
          this.TrainingList = [];
          this.getTrainingList();
        },
        (err) => {}
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      this.showDialogReg = true;
    }
  }

  AddApply(data) {
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.service.putCall(url, {}).subscribe(
        (data: any) => {
          this.userSvc.showSuccess(data.respMessage);
          this.TrainingList = [];
          this.getTrainingList();
        },
        (err) => {}
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      this.showDialogReg = true;
    }
  }
}
