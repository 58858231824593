import { Component, OnInit, OnDestroy, ViewChild, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_URLS } from '../../../Models/url';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { TenantId } from '../../../Models/url';
import { CommonService } from '../../../services/common.service'
import { ModaldialogComponent } from '../../../pages/modaldialog/modaldialog.component'

@Component({
  selector: 'app-complaint-compose-modal',
  templateUrl: './complaint-compose-modal.component.html',
  styleUrls: ['./complaint-compose-modal.component.css']
})
export class ComplaintComposeModalComponent implements OnInit ,AfterViewInit{
  @ViewChild('choose',{static: false}) ch :ElementRef;
  @Output() notify:EventEmitter<string> = new EventEmitter<string>()
  @ViewChild('f', {static: true}) f: NgForm;
  @ViewChild('takeComposeInput', {static: false})


  InputVar: ElementRef;
  studentId: any;
  email: any;
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  compose = {
    firstName: "",
    address: "",
    postcode: "",
    phone: "",
    comments: "",
    dept: "",
    study: "",
    email: ""
  }
  valid:boolean=false;

  constructor(
    private profileSvc: MyProfileService,
    private toast: UserService,
    private cms: CommonService,
    private modalCom: ModaldialogComponent,
  ) { }

  ngOnInit() {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    this.email = JSON.parse(localStorage.getItem('userDetails')).email
  }
  ngAfterViewInit(){

  }
  reset()
  {
    this.f.resetForm()
    this.InputVar.nativeElement.value = "";
  }
  handleFileInput(files) {
    this.fileSize = files[0].size
    const formData = new FormData();
    formData.append('images', files[0]);
    var url = APP_URLS.supportTicketUpload + '&frmSrc=complaint'
    this.profileSvc.postCallImg(url, formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
          this.ch.nativeElement.setAttribute('style', 'background-color: red !important');
        } else {
          if( data.length )
            this.fileName = data[0].name
            this.toast.showSuccess('Your chosen file is attached successfully. Now click on submit button')

            this.ch.nativeElement.setAttribute('style', 'background-color: #99CA3F !important');
        }

      }, err => {
        this.toast.showError(err.errorMessage)

      })
  }

  onInputChange(event: any){
    if (event.keyCode == 32 ) {
      if( ! event.target.value.length ){
        event.preventDefault();
      } else {
        event.target.value = event.target.value.replace(/^\s+|\s+$/g,"");
      }
    }
  }

  async submit() {
    // this.cms.loaderSet(true);
    var payload = {
      name:this.compose.firstName,
      address: this.compose.address,
      compose_mail: "",
      context_id: { student: this.studentId },
      context_type: "STUDENT",
      date_compl: "",
      dept: this.compose.dept,
      email: this.compose.email,
      from: this.email,
      inbox_type: "complaint",
      open_closed_thread: "open",
      phone: this.compose.phone,
      postcode: this.compose.postcode,
      student: this.studentId,
      study: this.compose.study,
      threads: [{
        attachments: [{ name:this.fileName, path: "http://localhost:3000/download/" }],
        created_by: { student: this.studentId },
        from: this.email,
        message: this.compose.comments,
        to: ["crm@abacademy.co.uk"]
      }]
    }
    let url = APP_URLS.supportTicketSubmit
    await this.profileSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode == 200 || res.respCode == 201) {
          // this.cms.loaderSet(false);
          this.profileSvc.setComplaintCompose('data')
          this.toast.showSuccess(res.respMessage);
          this.notify.emit("Complaints added")
        }
        else {
          // this.cms.loaderSet(false);
          this.toast.showError(res.respMessage)
        }
        this.modalCom.close();
        this.reset();
      },
      err => {
        // this.cms.loaderSet(false);
        this.toast.showError(err.errorMessage)
      })
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

}
