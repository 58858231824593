import {
  AfterContentInit,
  Component,
  DoCheck,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-spr-skillscan-table-modal',
  templateUrl: './spr-skillscan-table-modal.component.html',
  styleUrls: ['./spr-skillscan-table-modal.component.css'],
})
export class SprSkillscanTableModalComponent implements OnInit {
  @Input() skillScanDetails: any[] = [];
  @Input() formalReviewDetails: any[] = [];
  @Input() isLoading = false;
  displayName = '';

  constructor() {
    this.isLoading = true;
  }

  ngOnInit(): void {}

  getReviewScore(id, skillScan) {
    if (skillScan?.skilScanReviewInfo?.skilScanReview) {
      const { review_score } = skillScan.skilScanReviewInfo.skilScanReview;
      let rs = '';
      review_score.forEach((review) => {
        if (review._id == id) {
          rs = review.monthly_reviews_score;
        }
      });
      return rs;
    }
  }
}
