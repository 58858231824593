import { Component, OnInit,Output, OnDestroy,ViewChild,ElementRef,EventEmitter  } from '@angular/core';

import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.css']
})
export class ProfileSummaryComponent implements OnInit {

  @ViewChild('file', {static: false})

  InputVar: ElementRef;
  profileedit = "editFalg"
  studentId: any;
  profileDetails = {
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    nationality: {
      value: ""
    },
    ethnic_origin: {
      value: ""
    },
    title: "",
    land_line: "",
    martial_status: "",
    gender: "",
    date_of_birth: "",
    mailing_address: {
      line1: "",
      country: "",
      city: "",
      zip: ""
    },
    Signature: "",
    country: "",
    profile_picture: ""
  };
  profileEditDetails = {};
  waitingFlag: boolean = false
  profilePicpopupopen = 'none'
  showDialog: boolean = false;
  data: any
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  detailRecivedSubscription: Subscription;
  constructor(
    public service: MyProfileService, public cms: CommonService,
    private toast: UserService, private route: Router
  ) {
    this.detailRecivedSubscription = this.service.geteditprofile().subscribe(data => {
      if (data) {
        this.ngOnInit()
      }
    })
  }
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  ngOnInit(): void {
    var path = this.route.url
    if (path == "/profile/admission/personalDetails" || path == "/profile/admission") {
      this.service.setSidemenuTab("admission");
      this.service.setTab({ tabName : "Personal Details", isRefreshPermission : false })
    }
    else {
      this.service.setSidemenuTab("profilesummary");
    }

    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    // let data = JSON.parse(localStorage.getItem('profilesummaryData'))
    this.getProfileDetails()
  }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  picturepopupopen(data) {
    this.profilePicpopupopen = 'block';
    this.data = data

  }
  picturePopupClose() {
    document.getElementById('resetFile').click();
    this.profilePicpopupopen = 'none';
  }
  /**
   *
   * @param files File upload process
   *
   */
  handleFileInput(files) {
    if( files.length ){
      this.waitingFlag = true
      this.fileSize = files[0].size;

      if(this.fileSize > 5000000){
        this.toast.showError('File size is too large, please follow the instruction listed below on profile upload pop up.');
        this.waitingFlag = false;
        document.getElementById('resetFile').click();
        return;
      }


      var fileType = files[0].type;

      if (
				fileType != "image/png" &&
				fileType != "image/jpeg" &&
				fileType != "image/jpg"
			){
        this.toast.showError('File format is incorrect, please follow the instruction listed below on profile upload pop up.');
        this.waitingFlag = false;
        document.getElementById('resetFile').click();
        return;
      }

      if( fileType == 'image/png' || fileType == 'image/jpeg' || fileType == 'image/jpg'){
        const formData = new FormData();
        let url = APP_URLS.v1.profilePictureUpdate;
        formData.append('images', files[0]);
        this.service.postCall(url, formData)
          .subscribe((res: any) => {
            this.waitingFlag = false
            if (res.errorMessage) {
              this.fileName = ""
              this.InputVar.nativeElement.value = "";
              this.toast.showError(res.errorMessage.message || 'Invalid Format');
            } else if (res.details.profile_picture) {
                this.profileDetails.profile_picture = res.details.profile_picture;
                this.profileEditDetails = JSON.parse(JSON.stringify(this.profileDetails));
                this.toast.showSuccess('Profile picture uploaded successfully');
                document.getElementById('resetFile').click();
                this.picturePopupClose()
            }
          }, err => {
            this.waitingFlag = false
            this.profileDetails.profile_picture = "";
            this.profileEditDetails = JSON.parse(JSON.stringify(this.profileDetails));
            this.toast.showError('Unable to upload')
          })
      }
      else {
        this.waitingFlag = false
        this.fileName = ""
        this.InputVar.nativeElement.value = "";
        this.toast.showError('File format not allowed.')
      }
    }
  }

  getProfileDetails() {
    //this.cms.loaderSet(true);
    let url = APP_URLS.profileDetails + this.studentId;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      //this.cms.loaderSet(false);
      this.profileDetails = res.details;
      this.showDialog = false;
      this.profileEditDetails = JSON.parse(JSON.stringify(this.profileDetails));
      localStorage.setItem('profilesummaryData', JSON.stringify(this.profileDetails))
    },err=>{
      //this.cms.loaderSet(false);
    })
  }
}
