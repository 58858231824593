import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MatrixComponent implements OnInit {
  isLoading = false;
  displayName = '';
  studentDetail: any = {};
  matrixDetails: any[] = [];
  singleMatrixDetail: any;
  learner_comments: '';
  employer_comments: '';

  studentId = '';
  role: string;
  routeRecords: Subscription;

  constructor(
    private route: ActivatedRoute,
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService,
    private sanitizer: DomSanitizer
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getMatrixReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getMatrixReport();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  getMatrixReport(): void {
    this.isLoading = true;
    let url = APP_URLS.v1.matrixReport + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data?.matrixReport?.length > 0) {
          this.matrixDetails = data?.matrixReport;
          this.matrixDetails.map((value) => {
            if (value.description != '') {
              var val = value.description.replace(/\r?\n/g, '<br />');
              value.description = this.sanitizer.bypassSecurityTrustHtml(val);
            }
          });
        } else {
          this.matrixDetails = [];
        }
      },
      (err) => {
        this.isLoading = false;
        this.matrixDetails = [];
      }
    );
  }

  openEditMatrixModal(data): void {
    this.singleMatrixDetail = data;
  }

  contentLineBreak(val) {
    if (val == 'undefined' || val == '') {
      return '';
    }
    val = val.replace(/\r?\n/g, '<br />');
    var result = this.sanitizer.bypassSecurityTrustHtml(val);
    return result;
  }

  closeEditMatrixModal(): void {
    this.singleMatrixDetail = undefined;
  }

  updateMatrix(matrixInfo, id) {
    if (this.role == 'student' && !this.learner_comments) {
      this.toast.showError('Please fill mandatory fields');
      return;
    }

    if (this.role == 'employer' && !this.employer_comments) {
      this.toast.showError('Please fill mandatory fields');
      return;
    }

    let url = '';
    let payload = {
      skill_matrix_activity_ref: id,
      skilMatrixReview: {
        expextReqForPass: matrixInfo?.skilMatrixReview?.expextReqForPass || '',
        competent: matrixInfo?.skilMatrixReview?.competent || '',
        acMet: matrixInfo?.skilMatrixReview?.acMet || '',
        coach_comments: matrixInfo?.skilMatrixReview?.coach_comments || '',
        employer_comments:
          matrixInfo?.skilMatrixReview?.employer_comments ||
          this.employer_comments,
        learner_comments:
          matrixInfo?.skilMatrixReview?.learner_comments ||
          this.learner_comments,
        intend: matrixInfo?.skilMatrixReview?.intend || '',
        improvement: matrixInfo?.skilMatrixReview?.improvement || '',
      },
      students: this.studentId,
    };

    if (this.role == 'student') {
      payload['reviewedByStudent'] = true;
    } else {
      payload['reviewedByEmployer'] = true;
    }

    if (matrixInfo) {
      payload['_id'] = matrixInfo?._id;
      url = APP_URLS.v1.matrixUpdate;
    } else {
      url = APP_URLS.v1.matrixCreate;
    }

    this.service.postCallwithHeaders(url, payload).subscribe((data: any) => {
      if (data.toast.type == 'success') {
        this.getMatrixReport();
        this.toast.showSuccess(data?.toast?.message);
        document.getElementById('closeMatrixModal').click();
      } else {
        this.toast.showError(data?.toast?.message);
      }
    });
  }
}
