import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_URLS, TenantId } from '../../../Models/url';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';

@Component({
  selector: 'app-add-offthejob-modal',
  templateUrl: './add-offthejob-modal.component.html',
  styleUrls: ['./add-offthejob-modal.component.css'],
})
export class AddOffthejobModalComponent implements OnInit {
  @ViewChild('f', { static: true }) f: NgForm;
  // @ViewChild('takeComposeInput', { static: false }) : any;
  @ViewChild('sigPad') sigPad: { nativeElement: any };
  @Input() courseDetails: any;
  @Input() isMyOTJTab: boolean = false;
  @Input() activityDetails: any;
  @Output() saveDetails = new EventEmitter();

  files: NgxFileDropEntry[] = [];

  InputVar: ElementRef;
  fileSize: any;
  fileName = [];
  acceptStatement: false;
  acceptSignature: false;
  studentId: any;
  studentDetail = {
    Signature: '',
  };
  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';
  sigPadElement: any;
  context: any;
  isDrawing = false;
  isSigned = false;
  img = '';
  Signature = '';
  courseTermID = '';
  courseFullName = '';

  SubjectCategeory = [
    { id: 1, value: 'Learning Activities' },
    { id: 2, value: 'Practical Training Activities' },
    { id: 3, value: 'Learning Support Activities' },
  ];

  SubjectSubCategeory = {
    'Learning Activities': [
      { id: 11, value: 'Classes and workshops' },
      { id: 12, value: 'Learning activity/Assessment(Project)' },
      { id: 13, value: 'Lectures' },
      { id: 14, value: 'Online learning and webinars' },
      { id: 15, value: 'Masterclasses' },
      { id: 16, value: 'Relevant reading' },
      { id: 17, value: 'Research' },
      { id: 18, value: 'Lunch and learn sessions' },
      { id: 19, value: 'Role playing and simulation exercises' },
    ],
    'Practical Training Activities': [
      { id: 21, value: 'Job shadowing' },
      { id: 22, value: 'Mentoring' },
      { id: 23, value: 'Attending meetings' },
      { id: 24, value: 'Project work' },
      { id: 25, value: 'Professional networks' },
      { id: 26, value: 'Events and competitions' },
      { id: 27, value: 'Visits to wider parts of the departmentg' },
      {
        id: 28,
        value: 'Visits to industry and to other Government Departments',
      },
    ],
    'Learning Support Activities': [
      { id: 31, value: 'Writing self-assessments' },
      { id: 32, value: 'Writing assignments' },
      { id: 33, value: 'Reflective journals' },
      { id: 34, value: 'Revision' },
      { id: 35, value: 'Peer discussions' },
      { id: 36, value: 'Preparation for Assessments & Exams' },
      {
        id: 37,
        value:
          'One-to-one tutorials (with apprenticeship coach, line manager or colleagues) that contain guided learning or support for the apprenticeship',
      },
    ],
  };

  Training = [
    { id: 51, value: 'Main Provider' },
    { id: 52, value: 'Training Provider' },
    { id: 53, value: 'Employer' },
    { id: 54, value: 'All Parties' },
    { id: 55, value: 'External' },
  ];

  offthejobsObj = {
    subcategory: '',
    attachments: [],
    hours: '',
    minutes: '',
    start_date: '',
    start_time: '',
    student: '',
    whatislearned: '',
    acceptStudentDeclaration: false,
    acceptStudentSignature: false,
    workAndLearningActivities: '',
    workAndLearningActivities_sub: '',
    courseWithTerm_id: '',
    workActivityDoneComment: '',
    otj_activity: '',
  };
  role = '';
  routeRecords: Subscription;

  constructor(
    private profileSvc: MyProfileService,
    private courseSvc: CourseService,
    private toast: UserService,
    private route: ActivatedRoute
  ) {
    this.role = localStorage.getItem('role');
    if (this.role == 'student') {
      this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
      var profileData = localStorage.getItem('profilesummaryData');
      if (profileData != undefined && typeof profileData == 'string') {
        this.studentDetail = JSON.parse(profileData);
      }
    }

    if (this.role == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getProfileDetails();
      });
    }

    var today = new Date();
    this.offthejobsObj = {
      subcategory: '',
      attachments: [],
      hours: '',
      minutes: '',
      start_date:
        today.getFullYear() +
        '-' +
        ('0' + (today.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + today.getDate()).slice(-2),
      start_time: '',
      student: '',
      whatislearned: '',
      acceptStudentDeclaration: false,
      acceptStudentSignature: false,
      workAndLearningActivities: '',
      workAndLearningActivities_sub: '',
      courseWithTerm_id: '',
      workActivityDoneComment: '',
      otj_activity: '',
    };
  }

  ngOnInit(): void {
    if (!this.isMyOTJTab) {
      this.courseFullName = localStorage.getItem('TrainingFullName');
    } else {
      this.courseFullName = '';
    }

    this.courseTermID =
      this.courseDetails && this.courseDetails?.cou_app
        ? this.courseDetails?.cou_app._id
        : '';

    if (this.isMyOTJTab) {
      this.offthejobsObj.workAndLearningActivities =
        'Practical Training Activities';
      this.offthejobsObj.workAndLearningActivities_sub = 'Job shadowing';
      this.offthejobsObj.subcategory = 'All Parties';
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
      },
      (err) => {}
    );
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getDate() {
    var today = new Date();
    var inputValue = (<HTMLInputElement>document.getElementById('start_date'))
      .value;
    inputValue =
      today.getFullYear() +
      '-' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + today.getDate()).slice(-2);
  }

  reset() {
    this.f.resetForm();
    if (this.InputVar?.nativeElement) {
      this.InputVar.nativeElement.value = '';
    }
  }

  submitOffthejobs() {
    if (!this.acceptStatement) {
      this.toast.showError('Please accept declaration');
      return;
    }

    if (!this.acceptSignature) {
      this.toast.showError('Please accept signature');
      return;
    }

    if (!this.studentDetail.Signature) {
      this.toast.showError('Signature Required');
      return;
    }

    let selectFile = [];
    this.fileName.forEach((file) => {
      let obj = {
        name: file.dbName,
      };
      selectFile.push(obj);
    });

    this.submitBtnDisabled = true;
    let url = APP_URLS.postOffTheJobs;
    this.offthejobsObj.attachments = selectFile;
    this.offthejobsObj.student = this.studentId;
    this.offthejobsObj.acceptStudentDeclaration = this.acceptStatement;
    this.offthejobsObj.acceptStudentSignature = this.acceptSignature;
    this.offthejobsObj.courseWithTerm_id = this.courseTermID;

    if (this.isMyOTJTab) {
      this.offthejobsObj.otj_activity = this.activityDetails._id;
    }

    this.offthejobsObj.whatislearned = this.offthejobsObj.whatislearned.trim();
    this.offthejobsObj.workActivityDoneComment =
      this.offthejobsObj.workActivityDoneComment.trim();
    this.courseSvc.postCallwithHeaders(url, this.offthejobsObj).subscribe(
      (data: any) => {
        this.submitBtnDisabled = false;
        this.toast.showSuccess(
          'Your OTJ record submitted successfully. Course team will review your work/activity done and will update through email and notification to you'
        );
        this.saveDetails.emit(true);
        this.profileSvc.setPopup(false);
        this.reset();
      },
      (err) => {
        this.submitBtnDisabled = false;
        this.toast.showError('Error');
      }
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\:]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    if (this.context) {
      this.context.clearRect(
        0,
        0,
        this.sigPadElement.width,
        this.sigPadElement.height
      );
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  save() {
    this.img = this.sigPadElement.toDataURL('image/png');
  }
  submitBtnDisabled: boolean = false;
  dosignature() {
    if (this.isSigned) {
      this.img = this.sigPadElement.toDataURL('image/png');
      this.submitBtnDisabled = true;
      if (
        this.img !=
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'
      ) {
        let url = APP_URLS.DoSignature;
        var Payload = {
          signature: this.img,
        };
        this.profileSvc.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.toast.showSuccess('Your signature is saved successfully');
            }
            this.update();
          }
          if (res.errorCode) {
            this.toast.showError(res.errorMessage);
          }
        });
      } else {
        this.toast.showError('Please draw your signature');
        this.submitBtnDisabled = false;
      }
    }
  }

  async update() {
    let url = APP_URLS.profileDetails + this.studentId;
    let Payload = {
      Signature: this.Signature,
    };

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.studentDetail.Signature = this.Signature;
        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.studentDetail)
        );
        this.submitBtnDisabled = false;
      }
      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
        this.submitBtnDisabled = false;
      }
    });
  }
  seletedFiles = [];
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.isFileSizeAllowed(file.size)) {
            var fileExt = file.name.split('.').pop();
            var validExtensions = [
              'bmp',
              'doc',
              'docx',
              'gif',
              'jpeg',
              'jpg',
              'pdf',
              'rtf',
              'tif',
              'tiff',
              'txt',
              'xls',
              'xlsx',
              'ppt',
              'pptx',
              'png',
            ];

            if (validExtensions.indexOf(fileExt.toLowerCase()) == -1) {
              var err_notify =
                'Only formats are allowed : ' + validExtensions.join(', ');
              this.toast.showError(err_notify);
              return;
            }
            this.handleFileInput(file);
          } else {
            this.toast.showError(
              'Max size of a file allowed is 5mb, files with size more than 5mb are discarded.'
            );
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public isFileSizeAllowed(size) {
    let isFileSizeAllowed = false;
    if (size < 1024 * 5000) {
      isFileSizeAllowed = true;
    }
    return isFileSizeAllowed;
  }

  async handleFileInput(file) {
    const formData = new FormData();
    if (this.seletedFiles.indexOf(file.name) == -1) {
      this.seletedFiles.push(file.name);

      this.submitBtnDisabled = true;

      formData.append('images', file);
      await this.profileSvc
        .postCallImg(APP_URLS.fileUpload, formData)
        .subscribe(
          (data: any) => {
            this.submitBtnDisabled = false;
            if (data.errorMessage) {
              this.toast.showError(data.errorMessage);
            } else {
              if (data.length) {
                let obj = {
                  id: Math.random(),
                  actualName: file.name,
                  dbName: data[0].name,
                };
                this.fileName.push(obj);
              }

              this.toast.showSuccess('Success');
            }
          },
          (err) => {
            this.toast.showError('Error');
          }
        );
    } else {
      this.toast.showError('File Already Selected');
    }
  }

  public fileOver(event) {
    // console.log(event);
  }

  public fileLeave(event) {
    // console.log(event);
  }

  removeFile(file: any) {
    this.seletedFiles.splice(this.seletedFiles.indexOf(file.actualName), 1);
    this.fileName = this.fileName.filter((fi) => fi.id != file.id);
  }

  trainingProvidedByChange(val): any {
    if (val == 'External') {
      this.offthejobsObj.whatislearned = '';
    }
  }

  restMinuteValue(val) {
    if (val > '59') {
      this.offthejobsObj.minutes = '59';
      return;
    }
  }
}
