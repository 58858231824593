import { Component, OnInit } from "@angular/core";
import { APP_URLS } from "../../Models/url";
import { MyProfileService } from "../../services/my-profile.service";
import { CommonService } from "../../services/common.service";
import { Router, ActivatedRoute, RouterEvent } from "@angular/router";
import { UserService } from "../../services/user.service";
import { DomSanitizer } from "@angular/platform-browser";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

declare var google: any;

@Component({
	selector: "app-student-my-work-summary",
	templateUrl: "./student-my-work-summary.component.html",
	styleUrls: ["./student-my-work-summary.component.css"],
})
export class StudentMyWorkSummaryComponent implements OnInit {
	isWallet = true;
	isCurrency = true;
	taskDetails: any[] = [];
	task: any = {};
	totalItem = 0;
	countPagination = 25;
	page = 1;
	isLoading = true;
	isSubmitted = false;
	attach: any;
	walletDetails = {
		approvedWithdrawAmt: 0,
		earnedAmt: 0,
		waitingWithdrawAmt: 0,
		walletAmt: 0,
	};
	settingsDetails = {
		myWorkCurrency : ''
	};
	taskForm: FormGroup;
	isTaskSubmit = false;
	FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

	dateFilter = "";
	allfieldsearch = "";

	get taskformControls() {
		return this.taskForm.controls;
	}

	constructor(
		public service: MyProfileService,
		private route: Router,
		private sanitizer: DomSanitizer,
		private toast: UserService,
		private fb: FormBuilder
	) {
		this.taskForm = this.fb.group({
			link: this.fb.control(""),
			desc: this.fb.control("", Validators.required),
			attach: this.fb.control(""),
			myworktask_id: this.fb.control("", Validators.required),
		});
	}
	ngOnInit() {

		var settingsData = localStorage.getItem('settingsData');
		if( settingsData != undefined && typeof settingsData == 'string' ){
			this.settingsDetails = JSON.parse(settingsData);
			if( this.settingsDetails != null ){
				this.isWallet = ( this.settingsDetails?.myWorkCurrency == 'BP' ) ? false : true;
				this.isCurrency = ( this.settingsDetails?.myWorkCurrency == 'BP' ) ? false : true;
			}
		} else {
			this.getDropdowns();
		}

		this.getWalletDetails();
		this.getTaskDetails();

		//   const center = { lat: 50.064192, lng: -130.605469 };
		//   // Create a bounding box with sides ~10km away from the center point
		//   const defaultBounds = {
		//     north: center.lat + 0.1,
		//     south: center.lat - 0.1,
		//     east: center.lng + 0.1,
		//     west: center.lng - 0.1,
		//   };
		// const input = document.getElementById("autocomplete") as HTMLInputElement;
		// const options = {
		//   bounds: defaultBounds,
		//   componentRestrictions: { country: "us" },
		//   fields: ["address_components", "geometry", "icon", "name"],
		//   origin: center,
		//   strictBounds: false,
		//   types: ["establishment"],
		// };

		// const autocomplete = new google.maps.places.Autocomplete(input, options);
		// autocomplete.addListener('place_changed', () => {
		// })
	}

	getDropdowns() {
		let url = APP_URLS.v1.settingsData;
		this.service.getCall(url).subscribe((res: any) => {
			this.isWallet = ( res?.myWorkCurrency == 'BP' ) ? false : true;
			this.isCurrency = ( res?.myWorkCurrency == 'BP' ) ? false : true;
		});
	}

	navigate(): void {
		if (localStorage.getItem("KYC_COMPLETE") === "true") {
			this.route.navigate(["student-my-work/student-withdraw"]);
		} else {
			this.toast.showError("Need to Update KYC");
		}
	}

	takeTask(id: any) {
		let url = APP_URLS.v1.takeTask;
		this.service
			.postCallwithHeaders(url, { myworktask_id: id })
			.subscribe((res: any) => {
				this.getTaskDetails();
			});
	}

	viewTakenMywork(data) {
		this.task = data;
	}

	changePage(e: any) {
		if (!e) {
			e = 1;
		}
		this.page = e;
		this.getTaskDetails();
    const tab = document.getElementById("table");
    document.documentElement.scrollTop = 0;
	}

	viewMywork(taskdata): void {
		this.task = taskdata;
		var desc = taskdata?.description;
		desc = taskdata?.description.replace(/<[^>]*>?/gm, "");
		this.task[
			"projectDescription_html"
		] = this.sanitizer.bypassSecurityTrustHtml(taskdata?.description);
	}

	getTaskDetails() {
		this.taskDetails = [];
		this.isLoading = true;
		const filter = JSON.stringify({
			page: this.page,
			limit: this.countPagination,
		});
		let url =
			APP_URLS.v1.myTaskStudent +
			`?filter=${filter}` +
			"&selectDateFilter=" +
			this.dateFilter +
			"&allfieldsearch=" +
			this.allfieldsearch;
		this.service.getCallWithHeaders(url).subscribe((res: any) => {
			this.taskDetails = res?.res;
			this.totalItem =
				this.page == 1 ? res?.pagination.totalRecords : this.totalItem;
			this.isLoading = false;
		});
	}

	getWalletDetails() {
		let url =
			APP_URLS.v1.wallet +
			'?filter={"page":' +
			1 +
			',"limit":' +
			10 +
			"}";
		this.service.getCallWithHeaders(url).subscribe((res: any) => {
			this.walletDetails = res.amt;
		});
	}

	dateChange(e: any) {
		this.page = 1;
		this.countPagination = 25;
		this.dateFilter = e.target.value;
		this.getTaskDetails();
	}

	searchKey(e: any): void {
		this.page = 1;
		this.countPagination = 25;
		this.allfieldsearch = e.target.value;
		this.getTaskDetails();
	}

	submitTaskForm(): void {
		this.isSubmitted = true;
		this.taskForm.get("myworktask_id").patchValue({
			myworktask_id: this.task._id,
		});
		const data = {
			link: this.taskForm.get("link").value,
			attach: this.attach,
			myworktask_id: this.task?._id,
			description: this.taskForm.get("desc").value,
		};

		if (data.link !== "") {
			if (/^(ftp|http|https):\/\/[^ "]+$/.test(data.link) === false) {
				this.toast.showError("Link is not valid");
				return;
			}
		}

		if (this.taskForm.invalid) {
			return;
		}

		let url = APP_URLS.v1.taskSubmit;
		this.service.postCallwithHeaders(url, data).subscribe((res: any) => {
			if (res?.alertType === "success") {
				this.taskForm.reset();
				this.toast.showSuccess(res?.alertMessage);

				this.page = 1;
				this.countPagination = 25;
				this.getTaskDetails();

				document.getElementById("taskSubmitModal").click();
				this.isSubmitted = false;
			}
		});
	}

	handleChange(e: any) {
		const formData = new FormData();
		formData.append("images", e.target.files[0]);
		this.service.postCallImg(APP_URLS.attachmentUrl + '?from=my_work_task_submit', formData).subscribe(
			(data: any) => {
				if (data.errorMessage) {
					this.toast.showError(data.errorMessage);
				} else {
					if (data.length) {
						this.attach = data[0].name;
						this.toast.showSuccess(
							"Your chosen file is attached successfully. Now click on submit button"
						);
					}
				}
			},
			(err) => {
				this.toast.showError(err.errorMessage);
				this.attach = "";
			}
		);
	}

	tagsList = [];
	viewMyworkTags(tags: any) {
		this.tagsList = tags;
	}

	clear(): void {
		this.taskForm.reset();
		this.isSubmitted = false;
	}

	classes(task) {
		const obj = {
			"text-warning": task?.statusShow === "Waiting",
			"text-danger":
				task?.statusShow === "Not allowed (P)" ||
				task?.statusShow === "Not available" ||
				task?.statusShow === "Not allowed (T)" ||
				task?.statusShow === "Not allowed (P) - TE" ||
				task?.statusShow === "Not allowed (T) - TE" ||
				task?.statusShow === "Take expired",
			"text-success": task?.statusShow === "Taken",
			"text-info": task?.statusShow === "Completed",
		};
		return obj;
	}

	isStatusCheck(task) {
		return (
			task?.statusShow === "Taken" ||
			task?.statusShow === "Not available" ||
			task?.statusShow === "Completed" ||
			task?.statusShow === "Not allowed (P)" ||
			task?.statusShow === "Waiting" ||
			task?.statusShow === "Not allowed (T)" ||
			task?.statusShow === "Not allowed (P) - TE" ||
			task?.statusShow === "Not allowed (T) - TE" ||
			task?.statusShow === "Take expired"
		);
	}
}
