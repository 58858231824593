import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  tenantInfo: any = tenantInfo;

  userRole: any;
  constructor() {}

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
  }
}
