// import { Component, OnInit } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { async } from '@angular/core/testing';
import * as moment from 'moment';
import { UserService } from '../../services/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
// import html2canvas from 'html2canvas';
// import * as jsPDF from 'jspdf';
import html2PDF from 'jspdf-html2canvas';

@Component({
  selector: 'app-lms-inner-certificate',
  templateUrl: './lms-inner-certificate.component.html',
  styleUrls: ['./lms-inner-certificate.component.css']
})
export class LmsInnerCertificateComponent implements OnInit {
  // getCertficateDetails:any;
  @Input() chapterData: any;
  // template: any;
  certificateTemplate: any;
  //    data = {
  //     updated:'ytffty',
  //     course: 'fggdfg',
  //     certificate_id:'123',
  //     signature:'sdfsf.png',
  //     studentname:"ksdjfnkjsdf"

  // };
  // courseId:any;
  // courseName:any;
  data = {
    updated:'',
    course: '',
    chapterName:'',
    certificate_id:'',
    signature:'',
    studentname:'',
    termName:''
  };

  tenantSettingsData:any;
  templateList:any;
  cerTemplate:any;
  assignmentData = [];

  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.profileService.getCallWithHeaders(url).subscribe((res: any) => {
      this.tenantSettingsData = res.details[0];
    })
  }

  constructor( public service: CourseService,public profileService: MyProfileService, 
    private sanitizer: DomSanitizer, private userSvc: UserService, ) { 
    this.getTenantSettings();
  }

  ngOnInit(): void {
    let lmsData = JSON.parse(localStorage.getItem('lmsInnerData'))
    this.data.course = lmsData.cou_app.course.name;
    this.data.termName = ( lmsData.courseTermDetail && lmsData.courseTermDetail.length) ? lmsData.courseTermDetail[0].name : '';
    this.getCertificateDetails()
  }

  async getCertificateDetails(){
    let url = APP_URLS.getCertificate +'/'+`${this.chapterData._id}`
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      this.assignmentData = res.assignment;
      this.data.chapterName = res.assignment.chapter.name
      this.data.certificate_id = res.assignment.chapter.chapter_id
      this.getCertificate(this.data);
    });
  }

  resendRequestCertificate(){
    this.service.showSuccess('Your request for the certificate is already sent and waiting for the approval from the course department. For further information, you may contact your admin');
  }

  async requestCertificate(result){
      
    let url = APP_URLS.oldApi.CertificateRequest+result._id+'?target=assignment';
    let Payload = {
      certificate_request: true
    };
    await this.userSvc.putCallwithHeaders(url, Payload).subscribe((res: any) => {
      if(typeof res.toast != 'undefined'){
        if(res.toast.type == 'success'){
          this.userSvc.showSuccess(res.toast.message);
          result.certificate_request = true;
          result.certificate_approval = '';
        }
      }
      if(typeof res.errorMessage != 'undefined'){
        this.userSvc.showError(res.errorMessage);
      }
    });
  }

  download() {
    let filename = this.data.studentname + "-" + this.data.course + '.pdf'
    var data = document.getElementById("certificate");
    
    // html2canvas(data).then(canvas => {
    //     var pdf = new jsPDF('a4');
    //     var imgData = canvas.toDataURL('image/jpeg', 1.0);
    //     pdf.addImage(imgData, 'JPEG', 10, 10, 180, 150); 
    //     pdf.save(filename);
    // });
    html2PDF(data, {
      jsPDF: {
          unit: 'px', format: 'a3', orientation: 'landscape'
      },
      html2canvas: {
          scrollX: 0,
          scrollY: -window.scrollY,
          //allowTaint : true,
      },
      imageType: 'image/jpeg',
      output: filename
    });  
  }

  generateCertificate(data :any, encodeData : any){
    
    var snippet = document.createElement("div");
    // var certificateTemplate = this.cerTemplate.replace(/\[Signature\]/g, "<img id='auth_sign' src='" + APP_URLS.FILE_PATH_LINODE + "signature/" + this.tenantSettingsData.settings.signature + "' style='width:100px;'>");
    var certificateTemplate = this.cerTemplate.replace(/\[Signature\]/g, "<img id='auth_sign' src='" + encodeData + "' style='width:100px;'>");
    snippet.innerHTML = certificateTemplate;
    Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {
        var text = ele.src
        var text2 = ele.src
        let logo = tenantInfo.logoUrl
        let backgroundlogo = '../../../assets/images/certificatebg.png'
        let val = location.origin + "/[sitelogo]"
        let val2 = location.origin + "/[backgroundlogo]"

        if (ele.src == val) {
            text = text.replace(text, logo)
            ele.src = text
        }
        if (ele.src == val2) {
            text = text.replace(text2, backgroundlogo)
            ele.src = text
        }
    });

    Array.from(snippet.querySelectorAll('div')).forEach(function (ele) {
        var text = ele.textContent
        var text2 = ele.textContent
        var text3 = ele.textContent
        var text4 = ele.textContent
        var first_name = JSON.parse(localStorage.getItem('profilesummaryData')).first_name;
        var middle_name = JSON.parse(localStorage.getItem('profilesummaryData')).middle_name;
        var last_name = JSON.parse(localStorage.getItem('profilesummaryData')).last_name;
        var DisplayName = first_name + ' ' + middle_name + ' ' + last_name
        const date = new Date()
        data.studentname = DisplayName
        
        if (ele.textContent == "[studentname]") {
            text = text.replace(text, DisplayName)
            ele.textContent = text

        }
        
        if( ele.textContent == "Certified: [date]"){
            // var updateDate = new Date(data.updated).toJSON().slice(0, 10);
            // var dArr = updateDate.split("-");  
            // var result = dArr[2]+ "/" + dArr[1]+ "/" + dArr[0]; 
            text = text.replace(text3, 'Certified: ' + moment().format("DD/MM/YYYY"));
            ele.textContent = text;
        }
        
        if( ele.textContent == "Certificate code:[certificateCode]"){
            text = text.replace(text4, 'Certified code: ' + data.certificate_id);
            ele.textContent = text;
        }

        if (ele.textContent == "Term : [termname]") {
            text = text.replace(text2, data.termName)
            ele.textContent = text
        }

        if (ele.textContent == "[coursename]") {
            text = text.replace(text2, data.course)
            ele.textContent = text
        }

        if (ele.textContent == "[taskname] test") {
            text = text.replace(text2, data.course)
            ele.textContent = text
        }

        if (ele.textContent == "Has successfully completed the [chaptername] in") {
          text = text.replace(text2, 'Has successfully completed the '+ data.chapterName + ' in')
          ele.textContent = text
        }
    });

    Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
        var text3 = ele.textContent
        var text = ele.textContent
        const date = moment().format("DD/MM/YYYY");
        
        if (ele.textContent == "[date]") {
            text = text.replace(text3, date)
            ele.textContent = text
        }

    });
    this.certificateTemplate = this.sanitizer.bypassSecurityTrustHtml(snippet.innerHTML);   
  }

  getEncodedUrl( data ){
    var encodeData = '';
    if( this.tenantSettingsData.settings.signature && this.tenantSettingsData.settings.signature != '' ){
        let url = APP_URLS.v1.getEncodedUrl;
        var Payload = { path : APP_URLS.FILE_PATH_LINODE + "signature/" + this.tenantSettingsData.settings.signature }
        this.service.postCall(url, Payload).subscribe((res: any) => {
            encodeData = 'data:image/jpeg;base64,' + res.encodeData;
            this.generateCertificate( data, encodeData );
        },
        err => {
            this.generateCertificate( data, encodeData );
        })
    } else {
        this.generateCertificate( data, encodeData );
    }
  }

  getCertificate(data) {
    
    let url = APP_URLS.v1.getAllCertificateTemplates;
    this.profileService.getCallWithHeaders(url).subscribe((res: any) => {

      this.templateList = res.resTemp;
      this.templateList.forEach((x:any,i)=>{
          if( x.name == 'Chapter Completion Certificate' ){
              this.cerTemplate = x.template_text_view;
              this.getEncodedUrl( data );
              return false;
          }
      });
    })
  }

}
