import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { APP_URLS, TenantId } from '../../Models/url';
import { IPayPalConfig, ICreateOrderRequest, IPurchaseUnit } from 'ngx-paypal';
import { Router, ActivatedRoute } from '@angular/router';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { CourseService } from 'src/app/services/course.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  fileUrl = APP_URLS.FILE_PATH_LINODE;

  isLoading = false;
  submitted = false;
  public payPalConfig?: IPayPalConfig;
  showSuccess;
  listCount = 0;
  CartDetails = [];
  TotalCartFee = 0;
  mailId = '';
  mobNumberPattern = '^[0-9]*$';
  emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  transcationPattern = '[a-zA-Z0-9]+';

  addressForm: FormGroup;

  billing = {
    fname: '',
    mobilenumber: '',
    pincode: '',
    locality: '',
    address: '',
    city: '',
    landmark: '',
    nationality: '',
  };
  getAddressData = [];
  paynow: boolean = true;
  subscription: boolean = false;
  emioption: boolean = false;

  default = '';
  paymentID = '';
  payment_month = '';
  payment_type = 'instantpay';
  CartList = [];
  student_Id = '';

  registertoken = '';
  installamount = '';
  emailId = '';

  input: any;
  countryObj = {
    dialCode: '',
  };
  iti: any;
  countryCode = '';

  get af() {
    return this.addressForm.controls;
  }

  constructor(
    private userSvc: UserService,
    private router: Router,
    public service: MyProfileService,
    public courseservice: CourseService,
    private formBuilder: FormBuilder
  ) {
    this.addressForm = this.formBuilder.group({
      actiontype: this.formBuilder.control('add', Validators.required),
      tenantId: this.formBuilder.control(TenantId, Validators.required),
      fname: this.formBuilder.control('', Validators.required),
      lname: this.formBuilder.control('', Validators.required),
      email: this.formBuilder.control('', Validators.required),
      mobile: this.formBuilder.control('', Validators.required),
      address: this.formBuilder.control(''),
      city: this.formBuilder.control('', Validators.required),
      locality: this.formBuilder.control('', Validators.required),
      pincode: this.formBuilder.control('', Validators.required),
      nationality: this.formBuilder.control('', Validators.required),
      _id: this.formBuilder.control(''),
      alterphone: this.formBuilder.control(''),
    });
  }

  ngOnInit(): void {
    console.log();

    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.student_Id = data._id;
      this.mailId = data.email;
    }

    // if( data != null){
    //   this.access_token=data.access_token;
    // }
    let dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');

      if (this.student_Id && localStorage.getItem('token')) {
        this.getaddmultiCart();
      }

      // let url = APP_URLS.cartmulti;
      // let payload = {
      //   courseids : this.CartList
      // }
      //  this.userSvc.registerPutCall(url,payload,).subscribe((res:any)=>{
      //   if(res.suc_arr.length > 0 || res.err_arr.length > 0){
      //     this.courseservice.setcart('data')
      //      localStorage.removeItem('cartdata');
      //      this.router.navigate(['/Cart'])
      //   }
      // })
      // this.addtocartCall();
      // this.getaddmultiCart()
    }

    // let data = JSON.parse(localStorage.getItem('userDetails'))
    // if (data) {
    //   this.mailId = data.email;

    // }
    // this.initConfig(this.TotalCartFee) //ashok
    this.getDropdowns();
    this.getCart();
    this.getAddress();
  }

  ngAfterViewInit(): void {
    const userDetails = localStorage.getItem('profilesummaryData');

    if (userDetails) {
    }

    setTimeout(() => {
      this.input = document.querySelector('#mobile');
      console.log(this.input);
      this.iti = intlTelInput(this.input);
      this.iti.setCountry('gb');
    }, 1000);
  }

  @HostListener('document:countrychange', ['$event'])
  getCountry(e) {
    this.countryObj = this.iti.getSelectedCountryData();
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  openAddress(data) {
    if (data) {
      if( data.mobile != '' ){
				var mobileArr = data.mobile.split('-');
				if( mobileArr.length > 0 ){
					var mobileCode = mobileArr[0].substring(1);
					var index = this.iti.countries.findIndex(x => x.dialCode == mobileCode && x.areaCodes == null);
					if( this.iti.countries[index] && this.iti.countries[index].iso2 ){
						var so2code = this.iti.countries[index].iso2;
						this.iti.setCountry(so2code);
					}
				}
			}
      this.addressForm = this.formBuilder.group({
        actiontype: this.formBuilder.control('edit', Validators.required),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control(data.fname, Validators.required),
        lname: this.formBuilder.control(data.lname || ''),
        email: this.formBuilder.control(data.email || ''),
        mobile: this.formBuilder.control(
          data.mobile || '',
          Validators.required
        ),
        address: this.formBuilder.control(data.address || ''),
        city: this.formBuilder.control(data.city || '', Validators.required),
        locality: this.formBuilder.control(
          data.locality || '',
          Validators.required
        ),
        pincode: this.formBuilder.control(
          data.pincode || '',
          Validators.required
        ),
        nationality: this.formBuilder.control(
          data.nationality || '',
          Validators.required
        ),
        _id: this.formBuilder.control(data._id || ''),
        alterphone: this.formBuilder.control(data.alterphone || ''),
      });
    } else {
      this.submitted = false;
      this.addressForm = this.formBuilder.group({
        actiontype: this.formBuilder.control('add', Validators.required),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control(''),
        lname: this.formBuilder.control(''),
        email: this.formBuilder.control(''),
        mobile: this.formBuilder.control(''),
        address: this.formBuilder.control(''),
        city: this.formBuilder.control(''),
        locality: this.formBuilder.control(''),
        pincode: this.formBuilder.control(''),
        nationality: this.formBuilder.control(''),
        _id: this.formBuilder.control(''),
        alterphone: this.formBuilder.control(''),
      });
    }
  }

  resetAddressForm() {
    this.addressForm.reset({
      actiontype: 'add',
      tenantId: TenantId,
    });
  }

  closeAddress() {
    this.submitted = false;
    this.resetAddressForm();
  }

  addAddress() {
    this.submitted = true;

    var number = this.addressForm
      .get('mobile')
      .value.substring(
        this.addressForm.get('mobile').value.lastIndexOf('-') + 1
      );

    this.addressForm.patchValue({
      mobile: number,
    });

    if (this.addressForm.invalid) {
      console.log(this.addressForm);
      return;
    }

    if (this.countryObj?.dialCode !== '') {
      this.countryCode =
        this.countryObj?.dialCode != 'undefined'
          ? this.countryObj?.dialCode
          : this.countryCode;
    }

    if (number.length < 5 || number.length > 15) {
      this.userSvc.showError(
        'Mobile number Minimum 5 Numbers and Maximum 15 Numbers '
      );
      return;
    }

    this.addressForm.patchValue({
      mobile: '+' + this.countryCode + '-' + number,
    });

		let url = APP_URLS.updateManaeAddress;
		this.emailId = this.addressForm.get("email").value;
		if (this.student_Id) {
			this.userSvc
				.putCallwithHeaders(url, this.addressForm.value)
				.subscribe((data: any) => {
					if (data.respCode) {
						this.resetAddressForm();
						document
							.getElementById("address-modal-close-btn")
							.click();
						this.getAddress();
						if (this.getAddressData?.length == 0) {
							this.getProfileDetails("updateAddress");
						} else {
							this.userSvc.showSuccess(data.respMessage);
						}
					} else {
						this.userSvc.showError(data.errorMessage);
					}
				});
		} else {
			this.userSvc
				.putCall(url, this.addressForm.value)
				.subscribe((data: any) => {
					if (data.access_token) {
						this.registertoken = data?.access_token;
						localStorage.setItem("token", data?.access_token);
					}
					if (data.respCode) {
						this.resetAddressForm();
						document
							.getElementById("address-modal-close-btn")
							.click();
						this.getAddress();
						if (this.getAddressData?.length == 0) {
							this.getProfileDetails("updateAddress");
						} else {
							this.userSvc.showSuccess(data.respMessage);
						}
					} else {
						this.userSvc.showError(data.errorMessage);
					}
				});
		}
    	this.submitted = false;
	}

  async getCart() {
    if (this.student_Id) {
      let url = APP_URLS.getcart;
      let payload = {};
      await this.userSvc
        .postCallwithHeaders(url, payload)
        .subscribe((res: any) => {
          this.listCount = res.listCount;
          this.CartDetails = res.list;
          // this.userSvc.setCart("cart")
          let total = 0;
          let totalDiscount = 0;
          res.list.forEach((x) => {
            totalDiscount = totalDiscount + x.course_discount;
            total = total + x.course.course_fee_final;
          });
          this.TotalCartFee = total;
          this.initConfig(this.TotalCartFee);
          // this.selectEMI('3')
          // this.selectSubscription('Month')
        });
    } else {
      let url = APP_URLS.getcart;
      let payload = {
        courseids: this.CartList,
        tenantId: TenantId,
      };
      await this.userSvc.postCall(url, payload).subscribe((res: any) => {
        this.listCount = res.listCount;
        this.CartDetails = res.list;
        // this.userSvc.setCart("cart")
        let total = 0;
        res.list.forEach((x) => {
          total = total + x.course.course_fee_final;
        });
        this.TotalCartFee = total;
        this.initConfig(this.TotalCartFee);
      });
    }
  }

  payNow() {
    this.paynow = true;
    this.subscription = false;
    this.emioption = false;
    this.payment_type = 'instantpay';
    this.payment_month = '';
    this.payPalConfig = undefined;
    setTimeout(() => {
      this.initConfig(this.TotalCartFee);
    }, 1000);
  }
  Subscription() {
    this.paynow = false;
    this.subscription = true;
    this.emioption = false;
    this.payment_type = 'Subscription';
    this.selectSubscription('Month');
  }
  Emi() {
    this.paynow = false;
    this.subscription = false;
    this.emioption = true;
    this.payment_type = 'Installment';
    this.selectEMI('3');
  }

  selectEMI(val) {
    this.payment_month = val;
    let totalinstall = 0;
    this.payment_month = val;
    if (val == '3') {
      let total = 0;
      this.CartDetails.forEach((x) => {
        total = total + parseInt(x.course.installment.month[3]);
      });
      totalinstall = total;
    }
    if (val == '6') {
      let total = 0;
      this.CartDetails.forEach((x) => {
        total = total + parseInt(x.course.installment.month[6]);
      });
      totalinstall = total;
    }
    if (val == '9') {
      let total = 0;
      this.CartDetails.forEach((x) => {
        total = total + parseInt(x.course.installment.month[9]);
      });
      totalinstall = total;
    }
    this.installamount = 'Amount: $ ' + totalinstall;
    this.payPalConfig = undefined;
    setTimeout(() => {
      this.initConfig(totalinstall);
    }, 1000);
  }

  selectSubscription(data) {
    let totalinstall = 0;
    this.payment_month = data;
    if (data == 'Month') {
      let total = 0;
      this.CartDetails.forEach((x) => {
        total = total + parseInt(x.course.subcription.month);
      });
      totalinstall = total;
    }
    if (data == 'Year') {
      let total = 0;
      this.CartDetails.forEach((x) => {
        total = total + parseInt(x.course.subcription.year);
      });
      totalinstall = total;
    }
    this.installamount = 'Amount: $ ' + totalinstall;
    this.payPalConfig = undefined;
    setTimeout(() => {
      this.initConfig(totalinstall);
    }, 1000);
  }

  continue() {
    if (this.isUserActivated) {
      if (this.getAddressData?.length === 0) {
        this.userSvc.showError('Add Billing Address details');
      } else {
        this.getProfileDetails('validateUser');
      }
    } else {
      if (this.student_Id) {
        document.getElementById('collapseTwo').classList.remove('show');
        document.getElementById('collapseThree').classList.add('show');
      } else {
        this.getProfileDetails('validateUser');
      }
    }
  }

  selectCourse(data) {
    this.courseservice.selectCourseById(data.course);
    this.courseservice.senddataToHeader(data.course);
    this.router.navigate(['app/' + data.course.slug]);
  }

  selectedadd(data) {
    this.default = data._id;
    if (data) {
      this.addressForm.patchValue({
        actiontype: this.formBuilder.control('edit', Validators.required),
        tenantId: this.formBuilder.control(TenantId, Validators.required),
        fname: this.formBuilder.control(data.fname, Validators.required),
        lname: this.formBuilder.control(data.lname, Validators.required),
        email: this.formBuilder.control(data.email, Validators.required),
        mobile: this.formBuilder.control(data.mobile, Validators.required),
        address: this.formBuilder.control(data.address),
        city: this.formBuilder.control(data.city, Validators.required),
        locality: this.formBuilder.control(data.locality, Validators.required),
        pincode: this.formBuilder.control(data.pincode, Validators.required),
        nationality: this.formBuilder.control(
          data.nationality,
          Validators.required
        ),
        _id: this.formBuilder.control(data._id),
        _alterphoneid: this.formBuilder.control(data.alterphone),
      });
    }
  }

  getAddress() {
    let url = APP_URLS.getManageAddress;
    this.userSvc
      .getaddresscall(url, this.registertoken)
      .subscribe((res: any) => {
        this.emailId = res?.profile_details?.email;
        this.getAddressData = res?.profile_details?.manage_address || [];
        this.isLoading = false;

        if (
          this.getAddressData != undefined &&
          typeof this.getAddressData != 'string'
        ) {
          if (this.getAddressData.length) {
            this.default = this.getAddressData[0]?._id;
          } else {
            localStorage.removeItem('token');
          }
        } else {
          localStorage.removeItem('token');
        }
      });
  }

  isUserActivated: boolean = false;
  showLoginDialog: boolean = false;
  getProfileDetails(type: String) {
    let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    this.service.getCallWithHeaders(url).subscribe(
      (res: any) => {
        if (res?.userDetails?.activate == true) {
          this.isUserActivated = true;
          this.showLoginDialog = true;
        } else {
          this.showLoginDialog = false;
          if (type == 'updateAddress') {
            this.userSvc.showSuccess(
              'Your billing address is added successfully. Welcome email with activation link has been sent to your email id, please login to your email and activate your account then make the payment'
            );
          } else if (type == 'validateUser') {
            this.userSvc.showSuccess(
              'Welcome email with activation link has been sent to your email id, please login to your email and activate your account then make the payment'
            );
          }
        }
      },
      (err) => {}
    );
  }

  delete(data) {
    this.isLoading = true;
    let url = APP_URLS.updateManaeAddress;
    let payload = {
      actiontype: 'delete',
      _id: data._id,
    };
    this.userSvc.putCallwithHeaders(url, payload).subscribe((data: any) => {
      if (data.respCode == 202 || data.respCode == 200) {
        this.getAddressData = [];
        this.userSvc.showSuccess(data.respMessage);
        this.getAddress();
      } else {
        this.userSvc.showError(data.respMessage);
        this.isLoading = false;
      }
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  submit() {}

  bankTrasfer() {
    if (this.default == '') {
      this.userSvc.showError('Please Select Billing Address');
      return;
    }
    // if(this.paymentID == ""){
    //   this.userSvc.showError("Please Enter PaymentID");
    //   return;
    // }

    //     let paymentmonth = ""
    // if(this.payment_type == 'instantpay'){
    //    paymentmonth = ""
    // }
    // if(this.payment_type == 'Subscription'){
    //   paymentmonth = ""
    // }
    // if(this.payment_type == 'Installment'){
    //   paymentmonth = ""
    // }

    let url = APP_URLS.applicationspayment;

    let Payload = {
      payment_id: this.paymentID,
      payment_method: 'BANK TRANSFER',
      payment_month: this.payment_month,
      payment_type: this.payment_type,
      selected_address: this.addressForm.value,
      status: 'PAID',
    };

    this.userSvc
      .bankpostcall(url, Payload, this.registertoken)
      .subscribe((res: any) => {
        if (res.respCode) {
          this.userSvc.showSuccess(res.respMessage);
          this.router.navigate(['app/mytraining']);
        } else {
          this.userSvc.showError(res.errorMessage);
        }
      });
  }

  NationalityList = [];
  CountryList = [];
  EthnicOriginList = [];

  DropdownsData = [];

  getDropdowns() {
    let url = APP_URLS.ProfileDropdown;
    this.service.getCall(url).subscribe((res: any) => {
      this.DropdownsData = res.data;
      res.data.forEach((x) => {
        if (x.type == 'Nationality') {
          this.NationalityList.push(x);
        }
        if (x.type == 'Country') {
          this.CountryList.push(x);
        }
        if (x.type == 'EthnicOrigin') {
          this.EthnicOriginList.push(x);
        }
      });
    });
  }

  currencyCode = 'USD'; //GBP
  initConfig(price) {
    let amt = price;

    let items = [];

    this.CartDetails.forEach((x) => {
      let items = [];
      items.push({
        name: x.course.name,
        quantity: '1',
        category: 'COURSE',
        unit_amount: {
          currency_code: this.currencyCode,
          value: x.course.course_fee_final.toString(),
        },
      });
    });
    this.payPalConfig = {
      currency: this.currencyCode,
      clientId:
        'AQ9gb8EQlExCHEFxvWmvucXJ0wN09GnbySe8LCRRuuRA-ubIaVAOVvKEDzRJfgArcOW-LllD6u6d7cXb',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.currencyCode,
                value: amt.toString(),
                breakdown: {
                  item_total: {
                    currency_code: this.currencyCode,
                    value: amt.toString(),
                  },
                },
              },
              items: items,
              // [
              //   {
              //     name: 'Cours',
              //     quantity: '1',
              //     category: 'DIGITAL_GOODS',
              //     unit_amount: {
              //       currency_code: this.currencyCode,
              //       value: amt.toString(),
              //     },
              //   }
              // ]
            },
          ],
        },
      advanced: {
        // updateOrderDetails: {
        //   commit: true
        // }
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized-data',
          JSON.stringify(data)
        );
        console.log(
          'onApprove - transaction was approved, but not authorized-actions',
          JSON.stringify(actions)
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            JSON.stringify(details)
          );
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          JSON.stringify(data)
        );
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: (err) => {
        console.log('OnError', err);
      },
      onClick: () => {
        console.log('onClick');
      },
    };
  }

  async getaddmultiCart() {
    let dataofcart = localStorage.getItem('cartdata');
    if (dataofcart != null) {
      this.CartList = dataofcart.split(',');

      let url = APP_URLS.cartmulti;
      let payload = {
        courseids: this.CartList,
      };
      await this.userSvc
        .registerPutCall(url, payload, this.registertoken)
        .subscribe((res: any) => {
          if (res.suc_arr.length > 0 || res.err_arr.length > 0) {
            this.courseservice.setcart('data');
            localStorage.removeItem('cartdata');
            //  this.router.navigate(['/Cart'])
          }
        });
    }
  }

  bankTrasfer1() {}
}
