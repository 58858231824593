import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription,forkJoin } from 'rxjs';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-monthly-review',
  templateUrl: './monthly-review.component.html',
  styleUrls: ['./monthly-review.component.css'],
})
export class MonthlyReviewComponent implements OnInit {
  @ViewChild('f', { static: true }) f: NgForm;
  @ViewChild('sigPad') sigPad: { nativeElement: any };

  isLoading = false;
  studentId = '';
  userId = '';
  reviewDetails: any;
  singleReviewDetail: any;

  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';

  acceptStatement: any;
  acceptSignature: any;

  studentDetail = {
    Signature: '',
    signature: '',
  };
  sigPadElement: any;
  context: any;
  isDrawing = false;
  isSigned = false;
  img = '';
  Signature = '';
  isOpenSignatureModal = '';
  submitBtnDisabled: boolean;
  role: string;
  routeRecords: Subscription;

  apprenticeFeedBack = '';
  employerFeedBack = '';
  displayName = '';
  studentSign = '';
  employerSign = '';
  assessorsign = '';

  constructor(
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getMonthlyReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.userId = JSON.parse(localStorage.getItem('userDetails'))._id;
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');

        this.getMonthlyReport();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {
    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.studentDetail = JSON.parse(profileData);
    }
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    if (this.context) {
      this.context.clearRect(
        0,
        0,
        this.sigPadElement.width,
        this.sigPadElement.height
      );
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  save() {
    this.img = this.sigPadElement.toDataURL('image/png');
  }

  dosignature() {
    if (this.isSigned) {
      this.img = this.sigPadElement.toDataURL('image/png');
      this.submitBtnDisabled = true;
      if (
        this.img !=
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'
      ) {
        let url = APP_URLS.DoSignature;
        var Payload = {
          signature: this.img,
        };
        this.profileSvc.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.toast.showSuccess('Your signature is saved successfully');
            }
            this.update();
          }
          if (res.errorCode) {
            this.toast.showError(res.errorMessage);
          }
        });
      } else {
        this.toast.showError('Please draw your signature');
        this.submitBtnDisabled = false;
      }
    }
  }

  async update() {
    let url = '';
    let Payload = {};
    if (this.role == 'student') {
      url = APP_URLS.profileDetails + this.studentId;
      Payload = {
        Signature: this.Signature,
      };
    }
    if (this.role == 'employer') {
      url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
      Payload = {
        signature: this.Signature,
      };
    }

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        if (this.role == 'student') {
          this.studentDetail.Signature = this.Signature;
        }
        if (this.role == 'employer') {
          this.studentDetail.signature = this.Signature;
        }

        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.studentDetail)
        );
        this.submitBtnDisabled = false;
      }
      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
        this.submitBtnDisabled = false;
      }
    });
  }

  getMonthlyReport(): void {
    this.isLoading = true;
    let url = APP_URLS.v1.monthlyReview + '/' + this.studentId;

    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data?.reviewDetails?.length > 0) {
          this.reviewDetails = data?.reviewDetails;
        } else {
          this.reviewDetails = [];
        }
      },
      (err) => {
        this.isLoading = false;
        this.reviewDetails = [];
      }
    );
  }

  openReviewModal(data): void {
    $('#openMonthlyReviewModal').modal('show');
    window.scrollTo(0, 0);
    this.singleReviewDetail = data?.monthlyReview;
    this.singleReviewDetail['declarationByStudent'] =
      data?.declarationByStudent;
    this.singleReviewDetail['declarationByEmployee'] =
      data?.declarationByEmployee;
    this.singleReviewDetail['apprenticeSignature'] =
      data?.studentInfo?.Signature;
    this.singleReviewDetail['employerInfo'] = data?.employerInfo;
    this.displayName = data?.studentInfo?.displayName;
    // this.convertSignature();
    this.getSignature(data?.studentInfo?.Signature, 'student');
    this.getSignature(data?.employeeInfo?.signature, 'employee');
    this.getSignature(data?.employerInfo?.signature, 'employer');
  }

  getSignature(src, role): any {
    let url = APP_URLS.v1.getEncodedUrl;
    this.service
      .postCallwithHeaders(url, {
        path: this.signtureImage + src,
      })
      .subscribe((res: any) => {
        if (res && res.encodeData) {
          var encodedUrl = 'data:image/jpeg;base64,' + res.encodeData;
          if (role == 'student') {
            this.studentSign = encodedUrl;
          } else if (role == 'employee') {
            this.assessorsign = encodedUrl;
          } else {
            this.employerSign = encodedUrl;
          }
        }
      });
  }
  // convertSignature(): any {

  //   let url = APP_URLS.v1.getEncodedUrl;

  //   var stuSign = this.service.postCallwithHeaders(url, {
  //     path: this.studentSign,
  //   });

  //   var employeeSign = this.service.postCallwithHeaders(url, {
  //     path: this.assessorsign,
  //   });

  //   var employerSign = this.service.postCallwithHeaders(url, {
  //     path: this.employerSign,
  //   });

  //   forkJoin([stuSign, employeeSign, employerSign]).subscribe(
  //     (results: any) => {

  //       if (this.studentSign && results[0]) {
  //         this.studentSign =
  //           'data:image/jpeg;base64,' + results[0]?.encodeData;
  //           console.log(this.studentSign);
  //       }
  //       if (this.assessorsign && results[1]) {
  //         this.assessorsign =
  //           'data:image/jpeg;base64,' + results[1]?.encodeData;
  //       }
  //       if (this.employerSign && results[2]) {
  //         this.employerSign =
  //           'data:image/jpeg;base64,' + results[2]?.encodeData;
  //       }
  //     }
  //   );
  // }

  closeReviewModal(): void {
    this.singleReviewDetail = undefined;
    $('#openMonthlyReviewModal').modal('hide');
  }

  openSignModel(data): void {
    if (data?.monthlyReview?.reviewdate) {
      if (!this.compareDate(data?.monthlyReview?.reviewdate)) {
        this.toast.showError(
          'Review is not yet done, it is in due mode. Please contact your course department for further information. '
        );
        return;
      }
      this.apprenticeFeedBack = '';
      this.employerFeedBack = '';
      this.isOpenSignatureModal = '#openSignatureModal';
      this.singleReviewDetail = data;
    }
  }

  signModalClose(): void {
    this.acceptSignature = false;
    this.isOpenSignatureModal = '';
    this.singleReviewDetail = undefined;
  }

  submitDetails(): void {
    if (!this.acceptSignature) {
      this.toast.showError('Please accept signature');
      return;
    }

    if (this.role == 'student' && !this.studentDetail.Signature) {
      this.toast.showError('Signature Required');
      return;
    }

    if (this.role == 'student' && !this.apprenticeFeedBack) {
      this.toast.showError('Apprentice FeedBack Required');
      return;
    }

    if (this.role == 'employer' && !this.studentDetail.signature) {
      this.toast.showError('Signature Required');
      return;
    }

    if (this.role == 'employer' && !this.employerFeedBack) {
      this.toast.showError('Employer FeedBack Required');
      return;
    }

    let payload = {
      students: this.studentId,
      _id: this.singleReviewDetail._id,
    };

    if (this.role == 'student') {
      payload['declarationByStudent'] = true;
      payload['apprenticeFeedBack'] = this.apprenticeFeedBack;
    } else {
      payload['declarationByEmployer'] = true;
      payload['employerFeedBack'] = this.employerFeedBack;
    }

    let url = APP_URLS.v1.monthlyReview + '/' + this.studentId;
    this.service.postCallwithHeaders(url, payload).subscribe(
      (data: any) => {
        if (data?.toast?.type == 'success') {
          this.toast.showSuccess('Updated Successfully');
          this.acceptSignature = false;
          document.getElementById('closeSignatureModal').click();
          this.singleReviewDetail = undefined;
          this.getMonthlyReport();
        }
      },
      (err) => {
        this.toast.showError('Network Error');
      }
    );
  }

  compareDate(date) {
    var date1 = new Date();
    var date2 = new Date(date);

    if (date1 >= date2) {
      return true;
    } else {
      return false;
    }
  }
}
