import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calltoaction',
  templateUrl: './calltoaction.component.html',
  styleUrls: ['./calltoaction.component.css']
})
export class CalltoactionComponent implements OnInit {

  constructor( private router:Router) { }

  ngOnInit(): void {
  }

contact():void{
this.router.navigate(['app/contact']);
}
}
