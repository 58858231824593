import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-at-lab-records',
  templateUrl: './at-lab-records.component.html',
  styleUrls: ['./at-lab-records.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AtLabRecordsComponent implements OnInit {
  p: any = 1;
  countPagination = 50;
  addOtjRecords: Subscription;
  routeRecords: Subscription;
  studentId: any;
  showDialogOffthejob: boolean = false;
  isLoading: boolean;
  role = '';
  displayName = '';
  studentDetail: any = {};
  constructor(
    public service: CourseService,
    private sanitizer: DomSanitizer,
    private userSvc: UserService,
    private profileSvc: MyProfileService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getAtLabRecords();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getAtLabRecords();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }

    this.addOtjRecords = profileSvc.getPopup().subscribe((data) => {
      this.showDialogOffthejob = false;
    });
  }

  ngOnInit(): void {}

  onChangePage(pageOfItems: Array<any>) {
    this.p = pageOfItems;
  }

  ngOnDestroy() {
    this.addOtjRecords.unsubscribe();
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  atLabActivityDetails = [];
  getAtLabRecords(): void {
    this.isLoading = true;
    let url = APP_URLS.v1.getAtlabReports + '?id=' + this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        this.atLabActivityDetails = data?.atlabRecords;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
}
