import { AfterViewInit, Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-homeslide',
  templateUrl: './homeslide.component.html',
  styleUrls: ['./homeslide.component.css'],
})
export class HomeslideComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
