import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./attendance.component.css'],
})
export class AttendanceComponent implements OnInit {
  @ViewChild('f', { static: true }) f: NgForm;
  @ViewChild('sigPad') sigPad: { nativeElement: any };

  p: any = 1;
  countPagination = 50;
  isLoading = false;
  studentId: string;
  studentName: string;
  attendance: any[] = [];
  list: any[] = [];

  cousrseList: any[] = [];
  course: string;
  displayName = '';
  acceptStatement: any;
  acceptSignature: any;

  studentDetail: any = {
    Signature: '',
  };
  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';
  sigPadElement: any;
  context: any;
  isDrawing = false;
  isSigned = false;
  img = '';
  Signature = '';
  submitBtnDisabled: boolean = false;

  selectedAttendanceData: any;
  reasonByStudent = '';
  declarationByStudent: boolean = false;

  isDisplayReason = false;
  role = '';
  routeRecords: Subscription;
  studentSignature = '';

  constructor(
    public service: CourseService,
    private toast: UserService,
    private profileSvc: MyProfileService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getAttendanceDetails();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getAttendanceDetails();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }
  }

  ngOnInit(): void {
    this.studentName = JSON.parse(
      localStorage.getItem('userDetails')
    )?.displayName;
    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.studentDetail = JSON.parse(profileData);
    }
  }

  ngOnDestroy() {
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  onChangePage(pageOfItems: Array<any>) {
    this.p = pageOfItems;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  courseListOfAttendance = [];
  attendanseID = '';
  attendancelistByCourse = [];
  async getAttendanceDetails() {
    this.isLoading = true;
    this.attendance = [];
    this.attendancelistByCourse = [];
    let url = APP_URLS.v1.attendanceNew + this.studentId;
    this.service.getCallwithHeader(url).subscribe(async (data: any) => {
      const attendanceDetails = data?.attendanceList;
      if (attendanceDetails?.length > 0) {
        attendanceDetails.forEach(async (atten) => {
          let obj = {};
          obj['attendenceId'] = atten?.timetable_ref?.attendence_student?._id;

          atten?.courseId?.forEach((course) => {
            obj['courseName'] = course?.fullName;
          });

          obj['date'] = atten?.timetable_ref?.attendence_student?.date;

          obj['weekNumber'] = atten?.timetable_ref?.WeekNumber;
          obj['lesson'] = atten?.timetable_ref?.LessonCovered;
          obj['ispresent'] =
            atten?.timetable_ref?.attendence_student?.students?.ispresent;
          obj['timein'] =
            atten?.timetable_ref?.attendence_student?.students?.timein;
          obj['timeOut'] =
            atten?.timetable_ref?.attendence_student?.students?.timeOut;
          obj['GlhTime'] =
            atten?.timetable_ref?.attendence_student?.students?.GlhTime;
          obj['studentReason'] =
            atten?.timetable_ref?.attendence_student?.students?.reasonByStudent;
          obj['studentName'] =
            atten?.timetable_ref?.attendence_student?.students?.declarationByStudentId?.displayName;
          obj['employeeReason'] =
            atten?.timetable_ref?.attendence_student?.students?.reason;
          obj['empName'] =
            atten?.timetable_ref?.attendence_student?.students?.declarationByEmployeeId?.name;
          obj['Otj'] = atten?.timetable_ref?.attendence_student?.students?.otj;
          obj['isSigned'] =
            atten?.timetable_ref?.attendence_student?.students?.declarationByStudent;
          obj['empDate'] =
            atten?.timetable_ref?.attendence_student?.students?.markedByEmployeeDate;
          obj['studentSign'] =
            atten?.timetable_ref?.attendence_student?.students?.declarationByStudentId?.Signature;
          this.studentSignature =
            atten?.timetable_ref?.attendence_student?.students?.declarationByStudentId?.Signature;
          this.attendancelistByCourse.push(obj);
          console.log(obj);
        });

        await this.convertSignature();

        this.attendancelistByCourse = await this.attendancelistByCourse.filter(
          (at) => at?.status != ''
        );
           }
      this.isLoading = false;
    });
  }

  convertSignature(): any {
    let url = APP_URLS.v1.getEncodedUrl;
    this.service
      .postCallwithHeaders(url, {
        path: this.signtureImage + this.studentSignature,
      })
      .subscribe((res: any) => {
        if (res && res.encodeData) {
          var encodedUrl = 'data:image/jpeg;base64,' + res.encodeData;
          this.studentSignature = encodedUrl;
          return encodedUrl;
        }
      });
  }

  viewAttendance(data): void {
    if (data?.ispresent != 'A') {
      this.isDisplayReason = true;
    } else {
      this.isDisplayReason = false;
    }
    this.selectedAttendanceData = data;
  }

  clear() {
    if (this.context) {
      this.context.clearRect(
        0,
        0,
        this.sigPadElement.width,
        this.sigPadElement.height
      );
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  save() {
    this.img = this.sigPadElement.toDataURL('image/png');
  }

  dosignature() {
    if (this.isSigned) {
      this.img = this.sigPadElement.toDataURL('image/png');
      this.submitBtnDisabled = true;
      if (
        this.img !=
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'
      ) {
        let url = APP_URLS.DoSignature;
        var Payload = {
          signature: this.img,
        };
        this.profileSvc.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.toast.showSuccess('Your signature is saved successfully');
            }
            this.update();
          }
          if (res.errorCode) {
            this.toast.showError(res.errorMessage);
          }
        });
      } else {
        this.toast.showError('Please draw your signature');
        this.submitBtnDisabled = false;
      }
    }
  }

  async update() {
    let url = APP_URLS.profileDetails + this.studentId;
    let Payload = {
      Signature: this.Signature,
    };

    await this.profileSvc.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.studentDetail.Signature = this.Signature;
        localStorage.setItem('profilesummaryData', '');
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(this.studentDetail)
        );
        this.submitBtnDisabled = false;
      }
      if (res.errorCode) {
        this.toast.showError(res.errorMessage);
        this.submitBtnDisabled = false;
      }
    });
  }

  signModalClose(): void {
    this.reasonByStudent = '';
    this.declarationByStudent = false;
    this.acceptSignature = false;
  }

  submitDetails(): void {
    if (this.isDisplayReason) {
      if (!this.reasonByStudent) {
        this.toast.showError('Reason field is mandatory');
        return;
      }
    }

    if (!this.declarationByStudent) {
      this.toast.showError('Please accept declaration');
      return;
    }

    if (!this.acceptSignature) {
      this.toast.showError('Please accept signature');
      return;
    }

    if (!this.studentDetail.Signature) {
      this.toast.showError('Signature Required');
      return;
    }

    const payload = {
      attendenceId: this.selectedAttendanceData?.attendenceId,
      reasonByStudent: this.reasonByStudent,
      declarationByStudent: this.declarationByStudent,
    };

    let url =
      APP_URLS.updateAttendance + this.selectedAttendanceData?.attendenceId;
    this.service.postCallwithHeaders(url, payload).subscribe(
      (data: any) => {
        if (data?.toast?.type == 'success') {
          this.toast.showSuccess('Updated Successfully');
          this.reasonByStudent = '';
          this.declarationByStudent = false;
          this.acceptSignature = false;
          document.getElementById('closeSignatureModal').click();
          this.getAttendanceDetails();
        }
      },
      (err) => {
        this.toast.showError('Network Error');
      }
    );
  }
}
