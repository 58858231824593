import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-start-test-guide-note',
  templateUrl: './start-test-guide-note.component.html',
  styleUrls: ['./start-test-guide-note.component.css']
})
export class StartTestGuideNoteComponent implements OnInit {

  @Input() testType: any;
  constructor() { }

  ngOnInit(): void {
  }

}
