import {
	Component,
	OnInit,
	Input,
	ViewChild,
	HostListener,
  AfterViewInit
} from "@angular/core";
import { APP_URLS } from "../../Models/url";
import { TenantId } from "../../Models/url";
import { MyProfileService } from "../../services/my-profile.service";
import { UserService } from "src/app/services/user.service";
import { Router, ParamMap } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import intlTelInput from 'intl-tel-input';

@Component({
	selector: "app-edit-employer-profile",
	templateUrl: "./edit-employer-profile.component.html",
	styleUrls: ["./edit-employer-profile.component.css"],
})
export class EditEmployerProfileComponent implements OnInit, AfterViewInit {
	@Input() name: string;
	@ViewChild("sigPad") sigPad;
	@ViewChild("fc") form: any;

	profileForm: FormGroup;

	sigPadElement: any;
	context: any;
	isDrawing = false;
	isSigned = false;
	img = "";
	details: any;
  isSubmitted = false;
	@Input() profileDetails: any;

	empID = "";
	CountryList = [];
	DropdownsData = [];

	ChangePassword = {
		CurrentPassword: "",
		NewPassword: "",
		ConfirmPassword: "",
	};
	Signature = "";
	signtureImage = APP_URLS.FILE_PATH_LINODE + "signature/";
	path: string;
  input: any;
  countryObj = {
    dialCode : ''
  };
  iti: any;
  countryCode = '';

  get pf(){
    return this.profileForm.controls
  }
	constructor(
		public service: MyProfileService,
		private userSvc: UserService,
		private route: Router,
    private fb: FormBuilder,
	) {
		this.path = this.route.url.toString();
    this.profileForm = this.fb.group({
      email: this.fb.control('', Validators.required),
      name: this.fb.control('', Validators.required),
      phone: this.fb.control('', Validators.required),
      company_address: this.fb.control(''),
      note: this.fb.control(''),
      website: this.fb.control(''),
      company_name: this.fb.control(''),
      skype_id: this.fb.control(''),
      alternate_email: this.fb.control('')
    });
	}

	ngOnInit(): void {
		this.details = JSON.parse(JSON.stringify(this.profileDetails));
    this.profileForm = this.fb.group({
      email: this.fb.control(this.details?.email, Validators.required),
      name: this.fb.control(this.details?.name, Validators.required),
      phone: this.fb.control(this.details?.phone , Validators.required),
      purpose: this.fb.control(this.details?.purpose),
      company_address: this.fb.control(this.details?.company_address),
      note: this.fb.control(this.details?.note),
      website: this.fb.control(this.details?.website),
      company_name: this.fb.control(this.details?.company_name),
      skype_id: this.fb.control(this.details?.skype_id),
      alternate_email: this.fb.control(this.details?.alternate_email)
    })

    this.countryCode = this.profileForm.get('phone').value.substring(
      this.details?.phone.lastIndexOf("+") + 1,
      this.details?.phone.lastIndexOf("-") ,
    );

    if(this.countryCode == ''){
      this.countryCode = '44';
      this.profileForm.patchValue({
        phone: '+' + this.countryCode + '-' + this.profileForm.get('phone').value
      })
    }
    console.log(this.countryCode);

		this.empID = this.details._id;
		this.getDropdowns();
	}

  ngAfterViewInit(): void {
    this.input = document.querySelector("#phone");
    this.iti = intlTelInput(this.input);
  }

  @HostListener("document:countrychange", ["$event"])
	getCountry(e) {
    this.countryObj = this.iti.getSelectedCountryData();
	}


	@HostListener("document:mouseup", ["$event"])
	onMouseUp(e) {
		this.isDrawing = false;
	}

	onMouseDown(e) {
		if (!this.context) {
			this.sigPadElement = this.sigPad.nativeElement;
			this.context = this.sigPadElement.getContext("2d");
			this.context.strokeStyle = "#3742fa";
		}
		this.isDrawing = true;
		this.isSigned = true;
		const coords = this.relativeCoords(e);
		this.context.moveTo(coords.x, coords.y);
	}

	onMouseMove(e) {
		if (!this.context) {
			this.sigPadElement = this.sigPad.nativeElement;
			this.context = this.sigPadElement.getContext("2d");
			this.context.strokeStyle = "#3742fa";
		}
		if (this.isDrawing) {
			const coords = this.relativeCoords(e);
			this.context.lineTo(coords.x, coords.y);
			this.context.stroke();
		}
	}

	private relativeCoords(event) {
		const bounds = event.target.getBoundingClientRect();
		const x = event.clientX - bounds.left;
		const y = event.clientY - bounds.top;
		return { x: x, y: y };
	}

	clear() {
		if (this.context) {
			this.context.clearRect(
				0,
				0,
				this.sigPadElement.width,
				this.sigPadElement.height
			);
			this.context.beginPath();
			this.isSigned = false;
		}
	}

	save() {
		this.img = this.sigPadElement.toDataURL("image/png");
	}

	keyPress(event: any) {
		if (event.keyCode == 32) {
			event.preventDefault();
		}
		const pattern = /[0-9\+\-\ ]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	getDropdowns() {
		let url = APP_URLS.v1.settingsData;
		this.service.getCall(url).subscribe((res: any) => {
			// this.DropdownsData = res.selectData.nationality
			// res.data.forEach(x => {
			//   if (x.type == "Country") {
			//     this.CountryList.push(x)
			//   }
			// })
		});
	}

	dosignature() {
		if (this.isSigned) {
			this.img = this.sigPadElement.toDataURL("image/png");
			if (
				this.img !=
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC"
			) {
				let url = APP_URLS.DoSignature;
				var Payload = {
					signature: this.img,
				};
				this.service
					.postCallwithHeaders(url, Payload)
					.subscribe((res: any) => {
						if (res.respCode) {
							if (res.details) {
								this.Signature = res.details.siganture;
								this.userSvc.showSuccess(
									"Your signature is saved successfully"
								);
							}
							this.update();
						}
						if (res.errorCode) {
							this.userSvc.showError(res.errorMessage);
						}
					});
			} else {
				this.userSvc.showError("Please draw your signature");
			}
		}
	}

	async update() {

    this.isSubmitted = true;
    if (this.profileForm.invalid){
      return;
    }


    if(this.countryObj?.dialCode !== ''){
        this.countryCode = ( this.countryObj?.dialCode != 'undefined' ) ? this.countryObj?.dialCode : this.countryCode
        console.log(this.countryObj);
    }

    var number = this.profileForm.get('phone').value.substring(
      this.profileForm.get('phone').value.lastIndexOf("-") + 1,
    );


		let url = APP_URLS.v1.userDetails + "?apifrom=profile_summary";
		let Payload = {
			company_address: this.profileForm.get('company_address').value,
			alternate_email: this.profileForm.get('alternate_email').value,
			company_name: this.profileForm.get('company_name').value,
			email: this.profileForm.get('email').value,
			name: this.profileForm.get('name').value,
			note: this.profileForm.get('note').value,
			phone: '+' + this.countryCode +'-'+ number,
			skype_id: this.profileForm.get('skype_id').value,
			website: this.profileForm.get('website').value,
      purpose: this.details.purpose,
			business_type: this.details.business_type,
			addresses: this.details.addresses,
			signature: this.Signature || this.details.Signature,
		};


    if(/^[0-9+-]+$/.test(Payload.phone) == false){
      this.userSvc.showError("phone number is not valid");
				return;
    }

		if (Payload.website !== "") {
      if(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(Payload.website) == false){
				this.userSvc.showError("Link is not valid");
				return;
			}
		}

    function validateEmail(email) {
      const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(email);
    }


    if (Payload.email !== '') {
      if(!validateEmail(Payload.email)){
				this.userSvc.showError("Email is not valid");
				return;
			}
		}

    if (Payload.alternate_email !== "") {

      if(!validateEmail(Payload.alternate_email)){
				this.userSvc.showError("Alternate Email is not valid");
				return;
			}
		}



		await this.service.putCall(url, Payload).subscribe((res: any) => {
			if (res.respCode) {
				this.userSvc.showSuccess("Your profile updated successfully");
				this.service.seteditprofile("dummy");
				this.service.setPopup(false);
			}

			if (res.errorCode) {
				this.userSvc.showError(res.errorMessage);
			}
		});
	}

	changePassword(form: any) {
		if (
			this.ChangePassword.NewPassword == "" ||
			this.ChangePassword.CurrentPassword == "" ||
			this.ChangePassword.ConfirmPassword == ""
		) {
			this.userSvc.showError("Please Enter All the Fields");
			return;
		}

		if (
			this.ChangePassword.NewPassword !=
			this.ChangePassword.ConfirmPassword
		) {
			this.userSvc.showError(
				"Your new password and confirmation password should match"
			);
			return;
		}

		if (
			this.ChangePassword.NewPassword != "" &&
			this.ChangePassword.ConfirmPassword != "" &&
			this.ChangePassword.CurrentPassword != ""
		) {
			let url = APP_URLS.v1.changePassword;
			let Payload = {
				currentPassword: this.ChangePassword.CurrentPassword,
				newPassword: this.ChangePassword.NewPassword,
				verifyPassword: this.ChangePassword.ConfirmPassword,
			};
			this.service.putCall(url, Payload).subscribe((res: any) => {
				if (res.respCode) {
					this.userSvc.showSuccess(res.respMessage);
					this.ChangePassword.NewPassword = "";
					this.ChangePassword.CurrentPassword = "";
					this.ChangePassword.ConfirmPassword = "";
					this.service.seteditprofile("dummy");
					this.service.setPopup(false);
					form.resetForm();
				}
				if (res.errorCode) {
					this.userSvc.showError(res.respMessage);
				}
			});
		}
	}

	next() {
		this.update();
		setTimeout(() => {
			this.service.setTab({
				tabName: "Qualification",
				isRefreshPermission: false,
			});
			this.service.setPopup(false);
		}, 3000);
	}

	stopUserInput() {
		return false;
	}

	clearTab(type) {
		if (type == "password") {
			this.form.resetForm();
		} else if (type == "signature") {
			if (!this.Signature) this.clear();
		}
		this.details = JSON.parse(JSON.stringify(this.profileDetails));
	}
}



