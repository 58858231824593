import { Component, OnInit,ViewChild } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import {MyProfileService} from '../../services/my-profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterPipe } from '../../Models/filter.pipe';

@Component({
  selector: 'app-help-desk',
  templateUrl: './help-desk.component.html',
  styleUrls: ['./help-desk.component.css'],
  providers:[FilterPipe]
})
export class HelpDeskComponent implements OnInit {

  helpDeskDetails:any;
  helpList = [];
  helpRelatedTopics = [];
  helpTitle = '';
  innerhtmldata:any;
  userRole:any;

  constructor(
    public service: MyProfileService,
    private FilterSearch : FilterPipe,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    this.getHelpList();
  }

  allTopics = [];
  keyword = 'help_title';
  searchTxt = '';
  @ViewChild('auto') auto;

  selectEvent(item) {
    this.helpTitle = '';
    this.innerhtmldata = '';
    if( this.helpDeskDetails[item.parentIndex] ){
      this.helpRelatedTopics = [];
      this.helpRelatedTopics = this.helpDeskDetails[item.parentIndex].help_related
    }
    this.helpTitle = item.help_title;
    this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(item.description)
  }

  selectTopic( topic : any, index : any ){
    this.auto.clear();
    this.auto.close();
    this.helpRelatedTopics = [];
    this.helpTitle = '';
    this.innerhtmldata = '';
    this.helpRelatedTopics = topic;
    if( topic[index] ){
      this.helpTitle = topic[index].help_title;
      this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(topic[index].description)
    }
  }

  selectSubTopic( subTopic : any ){
    this.auto.clear();
    this.auto.close();
    this.helpTitle = '';
    this.innerhtmldata = '';
    this.helpTitle = subTopic.help_title;
    this.innerhtmldata = this.sanitizer.bypassSecurityTrustHtml(subTopic.description);
    window.scroll(0,0);
  }

  getHelpList() {
    let url = APP_URLS.v1.getHelpList;
    this.service.getCallWithHeaders(url).subscribe((resp: any) => {
      this.helpDeskDetails = resp.list;

      this.helpDeskDetails.forEach((x, i) => {
        x.help_related.forEach((title,j) => {
          title.parentIndex = i;
          this.allTopics.push(title)
        })
      });
      if( this.helpDeskDetails.length ){
        this.selectTopic( this.helpDeskDetails[0].help_related, 0 );
      }
    })
  }
}
