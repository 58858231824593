import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_URLS } from 'src/app/Models/url';
import { CourseService } from 'src/app/services/course.service';
import { UserService } from 'src/app/services/user.service';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-otj-activity',
  templateUrl: './otj-activity.component.html',
  styleUrls: ['./otj-activity.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OtjActivityComponent implements OnInit, OnDestroy {
  @ViewChild('f', { static: true }) f: NgForm;
  showDialogOffthejob: boolean = false;
  dialogSize = 'max';
  courseDetails: any;

  otjActivityDetails: any[] = [];
  isLoading: boolean;
  isLoadingCollapse: boolean;
  isOverAllLoadingAtLabLoading: boolean = false;
  studentId: any;

  competenceStandard: any[] = [];
  knowledge: any[] = [];
  activityDetails = {
    name: '',
    details: '',
    attachments: [],
  };

  atLabActivityName: any[] = [];
  selectedIndex: number;
  selectedIndexAtLab: number;
  addOtjInfo: any;
  addOtjRecords: Subscription;
  role = '';

  addCustomOtj = {
    activityName: '',
    activityDetails: '',
    exceptedOtjTime: '',
    hours: '',
    minutes: '',
    attachments: [],
  };

  html: '';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '300px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
      {
        name: 'Bold',
        class: 'bold',
        tag: 'b',
      },
    ],
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [['bold', 'italic'], ['fontSize']],
  };

  files: NgxFileDropEntry[] = [];
  fileName = [];
  seletedFiles = [];
  submitBtnDisabled: boolean;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  routeRecords: Subscription;
  displayName = '';
  studentDetail: any = {};

  constructor(
    public service: CourseService,
    private sanitizer: DomSanitizer,
    private userSvc: UserService,
    private profileSvc: MyProfileService,
    private route: ActivatedRoute
  ) {
    if (localStorage.getItem('role') == 'student') {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.studentId = userDetails._id;
      this.displayName = userDetails.displayName;
      this.role = 'student';
      this.getOTJReport();
    }

    if (localStorage.getItem('role') == 'employer') {
      this.routeRecords = this.route.paramMap.subscribe((params) => {
        this.studentId = params.get('id');
        this.getOTJReport();
        this.getProfileDetails();
      });
      this.role = 'employer';
    }

    this.addOtjRecords = this.profileSvc.getPopup().subscribe((data) => {
      this.showDialogOffthejob = false;
      this.getOTJReport();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.addOtjRecords.unsubscribe();
    if (this.routeRecords) {
      this.routeRecords.unsubscribe();
    }
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails + this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.studentDetail = res.details;
        this.displayName = this.studentDetail.displayName;
      },
      (err) => {}
    );
  }

  getOTJReport(): void {
    this.isLoading = true;
    let url = APP_URLS.v1.getOTJReport + '?student_id=' + this.studentId;
    this.service.getCallwithHeader(url).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data?.otjActivityReport?.length > 0) {
          this.otjActivityDetails = data?.otjActivityReport;

          this.otjActivityDetails.map((otj) => {
            let isCompleteKnw = true;
            otj?.knowledge.forEach((knw) => {
              if (
                knw?.completed_status == 'Pending' ||
                knw?.completed_status == 'Not Allowed'
              ) {
                isCompleteKnw = false;
                return;
              }
            });

            if (isCompleteKnw) {
              otj['isKnowledgeCompleteFully'] = true;
            } else {
              otj['isKnowledgeCompleteFully'] = false;
            }
            return otj;
          });
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  atlabClick(data): void {
    this.atLabActivityName = data?.atLabActivityName;
    if (this.atLabActivityName) {
      let url =
        APP_URLS.v1.getAtlabRecordStatus + '?student_id=' + this.studentId;
      var payload = {
        atlab_activity_id: this.atLabActivityName,
      };
      this.isOverAllLoadingAtLabLoading = true;
      this.userSvc.postCallwithHeaders(url, payload).subscribe(
        (res: any) => {
          if (res?.atlabInfo) {
            let arrATab = [];

            res?.atlabInfo?.forEach((server) => {
              this.atLabActivityName = this.atLabActivityName.map((local) => {
                if (local._id == server?.atlab_activity_ref?._id) {
                  local['status'] = server?.status;
                }
                return local;
              });
            });
            this.isOverAllLoadingAtLabLoading = false;
          }
        },
        (error) => {
          // this.cms.loaderSet(false);
          this.userSvc.showError('applicaion failed');
        }
      );
    }
  }

  openAccordionAtLabName(index) {
    if (this.selectedIndexAtLab == index) {
      this.selectedIndexAtLab = -1;
      return;
    }
    this.selectedIndexAtLab = index;
  }

  closeAtLabModal() {
    this.selectedIndexAtLab = -1;
  }

  activityNameClick(data): void {
    this.activityDetails['name'] = data?.activityName;
    this.activityDetails['details'] = data?.activityDetails;
    this.activityDetails['attachments'] = data?.attachments;
  }

  knowledgeClick(data): void {
    this.knowledge = data?.knowledge;
  }

  openAccordion(index, knw) {
    if (knw?.completed_status == 'Not Allowed' && this.role == 'student') {
      this.userSvc.showError(
        'Not allowed to see this course.Need to apply and get approve from admin'
      );
      return;
    }

    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
      return;
    }

    this.selectedIndex = index;
  }

  closeKnowledgeModal(): void {
    this.selectedIndex = -1;
  }

  competenceStandardClick(data): void {
    this.competenceStandard = data?.standards;
  }

  convertDataintoHtml(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  openAddOTJForm(data: any) {
    this.addOtjInfo = [];
    this.showDialogOffthejob = true;
    this.addOtjInfo = data;
  }

  finishATLab(data: any) {
    let url = APP_URLS.v1.submitATLabRecords;
    let payload = {
      atlab_activity_ref: data?._id,
    };
    this.isLoadingCollapse = true;
    this.userSvc.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        if (res.toast?.code) {
          this.userSvc.showSuccess('Successfully Updated');
          this.isLoadingCollapse = false;
          this.atLabActivityName = this.atLabActivityName.map((data) => {
            if (data._id == res?.record?.atlab_activity_ref) {
              data['status'] = 'Completed';
            }
            return data;
          });
          this.getOTJReport();
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('applicaion failed');
      }
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\:]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  finishLesson(data) {
    let url = APP_URLS.lmsInnerSubmit;
    let payload = {
      chapter: data?._id,
      student: this.studentId,
      course: data?.course,
      exam_type: 'lessoncomplete',
      review_status: 'A',
      review_iv_status: 'A',
      test_submitted_date: new Date(),
    };

    this.isLoadingCollapse = true;
    this.userSvc.putCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        if (res.respCode) {
          this.knowledge.map((knw) => {
            if (knw?._id == data?._id) {
              knw['completed_status'] = 'Completed';
            }
            return;
          });
          this.userSvc.showSuccess('Successfully Updated');
          this.isLoadingCollapse = false;
          this.getOTJReport();
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('applicaion failed');
      }
    );
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.handleFileInput(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  async handleFileInput(file) {
    const formData = new FormData();
    if (this.seletedFiles.indexOf(file.name) == -1) {
      this.seletedFiles.push(file.name);

      formData.append('images', file);
      await this.profileSvc
        .postCallImg(APP_URLS.fileUpload, formData)
        .subscribe(
          (data: any) => {
            if (data.errorMessage) {
              this.userSvc.showError(data.errorMessage);
            } else {
              if (data.length) {
                let obj = {
                  id: Math.random(),
                  actualName: file.name,
                  dbName: data[0].name,
                };
                this.fileName.push(obj);
              }
              this.userSvc.showSuccess('Success');
            }
          },
          (err) => {
            this.userSvc.showError('Error');
          }
        );
    } else {
      this.userSvc.showError('File Already Selected');
    }
  }

  public fileOver(event) {}

  public fileLeave(event) {}

  removeFile(file: any) {
    this.seletedFiles.splice(this.seletedFiles.indexOf(file.actualName), 1);
    this.fileName = this.fileName.filter((fi) => fi.id != file.id);
  }

  addCustomOTJ(): void {}

  submitCustomOTJ(): void {
    if (this.addCustomOtj.activityDetails == '') {
      this.userSvc.showError('Requirements Field is Mandatory');
      return;
    }
    this.submitBtnDisabled = true;
    let selectFile = [];
    this.fileName.forEach((file) => {
      let obj = {
        name: file.dbName,
      };
      selectFile.push(obj);
    });

    this.addCustomOtj.attachments = selectFile;

    if (this.addCustomOtj.hours != '' || this.addCustomOtj.minutes != '') {
      var hours = this.addCustomOtj.hours != '' ? this.addCustomOtj.hours : 0;
      var minutes =
        this.addCustomOtj.minutes != ''
          ? ':' + this.addCustomOtj.minutes
          : ':' + 0;
      this.addCustomOtj.exceptedOtjTime = hours + minutes;
    }

    let url = APP_URLS.v1.createCustomOTJ + '?student_id=' + this.studentId;
    this.userSvc.postCallwithHeaders(url, this.addCustomOtj).subscribe(
      (res: any) => {
        if (res) {
          this.submitBtnDisabled = false;
          document.getElementById('customSubmitModal').click();
          this.getOTJReport();
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('applicaion failed');
      }
    );
  }

  closeCustomOtjModal(): void {
    this.f.resetForm();
    this.addCustomOtj = {
      activityName: '',
      activityDetails: '',
      exceptedOtjTime: '',
      hours: '',
      minutes: '',
      attachments: [],
    };

    this.files = [];
    this.fileName = [];
    this.seletedFiles = [];
    this.submitBtnDisabled = false;
  }

  restMinuteValue(val) {
    if (val > '59') {
      this.addCustomOtj.minutes = '59';
      return;
    }
  }
}
