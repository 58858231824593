import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-homecarousel',
  templateUrl: './homecarousel.component.html',
  styleUrls: ['./homecarousel.component.css']
})
export class HomecarouselComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  showDialogReg = false;
  token:boolean=false;
  userDetail: any;
  student_Id = ''

  constructor(private userSvc:UserService) { }

  ngOnInit(): void {

    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;
    })

    var userData = localStorage.getItem('profilesummaryData');
    if( userData != undefined && typeof userData == 'string' ){
      this.userDetail = JSON.parse(userData);
      this.student_Id = this.userDetail._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.token=true

    }

  }


  gotoRegister(){
  }
}
