import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home-students',
  templateUrl: './home-students.component.html',
  styleUrls: ['./home-students.component.css']
})
export class HomeStudentsComponent implements OnInit {
  tenantInfo: any = tenantInfo;
  showDialogReg = false;
  token:boolean=false;
  userDetail: any;
  student_Id = '';
  constructor(private userSvc:UserService) { }

  ngOnInit(): void {


    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;
    })

    var userData = localStorage.getItem('profilesummaryData');
    if( userData != undefined && typeof userData == 'string' ){
      this.userDetail = JSON.parse(userData);
      this.student_Id = this.userDetail._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {

      this.token=true

    }

  }

}
