import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-blendedlearning',
  templateUrl: './blendedlearning.component.html',
  styleUrls: ['./blendedlearning.component.css']
})
export class BlendedlearningComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
